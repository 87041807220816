div.ap-wrapper {
  outline: none;
  height: 100%;
  display: flex;
  justify-content: center;
}
div.ap-wrapper .title-bar {
  display: none;
  top: -78px;
  transition: top 0.15s linear;
  position: absolute;
  left: 0;
  right: 0;
  box-sizing: content-box;
  font-size: 20px;
  line-height: 1em;
  padding: 15px;
  font-family: sans-serif;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
}
div.ap-wrapper .title-bar img {
  vertical-align: middle;
  height: 48px;
  margin-right: 16px;
}
div.ap-wrapper .title-bar a {
  color: white;
  text-decoration: underline;
}
div.ap-wrapper .title-bar a:hover {
  text-decoration: none;
}
div.ap-wrapper:fullscreen {
  background-color: #000;
  width: 100%;
  align-items: center;
}
div.ap-wrapper:fullscreen div.ap-player {
  position: static;
}
div.ap-wrapper:fullscreen .title-bar {
  display: initial;
}
div.ap-wrapper:fullscreen.hud .title-bar {
  top: 0;
}
div.ap-wrapper div.ap-player {
  text-align: left;
  display: inline-block;
  padding: 0px;
  position: relative;
  box-sizing: content-box;
  overflow: hidden;
  max-width: 100%;
  border-radius: 4px;
  font-size: 15px;
}
pre.ap-terminal {
  box-sizing: content-box;
  overflow: hidden;
  padding: 0;
  margin: 0px;
  display: block;
  white-space: pre;
  word-wrap: normal;
  word-break: normal;
  border-radius: 0;
  border-style: solid;
  cursor: text;
  border-width: 0.75em;
  font-family: Consolas, Menlo, 'Bitstream Vera Sans Mono', monospace, 'Powerline Symbols';
  font-variant-ligatures: none;
}
pre.ap-terminal .ap-line {
  letter-spacing: normal;
  overflow: hidden;
}
pre.ap-terminal .ap-line span {
  padding: 0;
  display: inline-block;
  height: 100%;
}
pre.ap-terminal .ap-line {
  display: block;
  width: 200%;
}
pre.ap-terminal .ap-line .ap-cursor-a {
  display: inline-block;
}
pre.ap-terminal .ap-line .ap-cursor-b {
  display: none;
  border-radius: 0.05em;
}
pre.ap-terminal .ap-line .ap-blink {
  visibility: hidden;
}
pre.ap-terminal.ap-cursor .ap-line .ap-cursor-a {
  display: none;
}
pre.ap-terminal.ap-cursor .ap-line .ap-cursor-b {
  display: inline-block;
}
pre.ap-terminal.ap-blink .ap-line .ap-blink {
  visibility: visible;
}
pre.ap-terminal .ap-bright {
  font-weight: bold;
}
pre.ap-terminal .ap-faint {
  opacity: 0.5;
}
pre.ap-terminal .ap-underline {
  text-decoration: underline;
}
pre.ap-terminal .ap-italic {
  font-style: italic;
}
pre.ap-terminal .ap-strikethrough {
  text-decoration: line-through;
}
div.ap-player div.ap-control-bar {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, #000000 25%, #000000 100%);
  color: #bbb;
  box-sizing: content-box;
  line-height: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.15s linear;
  user-select: none;
  z-index: 30;
}
div.ap-player div.ap-control-bar * {
  box-sizing: inherit;
  font-size: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}
div.ap-control-bar svg.ap-icon path {
  fill: #bbb;
}
div.ap-control-bar span.ap-playback-button {
  display: block;
  flex: 0 0 auto;
  cursor: pointer;
  height: 12px;
  width: 12px;
  padding: 10px;
}
div.ap-control-bar span.ap-playback-button svg {
  height: 12px;
  width: 12px;
}
div.ap-control-bar span.ap-timer {
  display: block;
  flex: 0 0 auto;
  min-width: 50px;
  margin: 0 10px;
  height: 100%;
  text-align: center;
  font-size: 11px;
  line-height: 34px;
  cursor: default;
}
div.ap-control-bar span.ap-timer span {
  display: inline-block;
  font-size: inherit;
}
div.ap-control-bar span.ap-timer .ap-time-remaining {
  display: none;
}
div.ap-control-bar span.ap-timer:hover .ap-time-elapsed {
  display: none;
}
div.ap-control-bar span.ap-timer:hover .ap-time-remaining {
  display: inline;
}
div.ap-control-bar .ap-progressbar {
  display: block;
  flex: 1 1 auto;
  height: 100%;
  padding: 0 10px;
}
div.ap-control-bar .ap-progressbar .ap-bar {
  display: block;
  position: relative;
  cursor: default;
  height: 100%;
  font-size: 0;
}
div.ap-control-bar .ap-progressbar .ap-bar .ap-gutter {
  display: block;
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #333;
}
div.ap-control-bar .ap-progressbar .ap-bar .ap-gutter .ap-gutter-fill {
  display: inline-block;
  height: 100%;
  background-color: #bbb;
  border-radius: 3px;
  z-index: 10;
}
div.ap-control-bar.ap-seekable .ap-progressbar .ap-bar {
  cursor: pointer;
}
div.ap-control-bar .ap-fullscreen-button {
  display: block;
  flex: 0 0 auto;
  width: 14px;
  height: 14px;
  padding: 9px;
  cursor: pointer;
}
div.ap-control-bar .ap-fullscreen-button svg {
  width: 14px;
  height: 14px;
}
div.ap-control-bar .ap-fullscreen-button svg:first-child {
  display: inline;
}
div.ap-control-bar .ap-fullscreen-button svg:last-child {
  display: none;
}
div.ap-wrapper.ap-hud .ap-control-bar {
  opacity: 1;
}
div.ap-wrapper:fullscreen .ap-fullscreen-button svg:first-child {
  display: none;
}
div.ap-wrapper:fullscreen .ap-fullscreen-button svg:last-child {
  display: inline;
}
span.ap-progressbar span.ap-marker-container {
  display: block;
  top: 0;
  bottom: 0;
  width: 21px;
  position: absolute;
  margin-left: -10px;
  z-index: 9;
}
span.ap-marker-container span.ap-marker {
  display: block;
  top: 13px;
  bottom: 12px;
  left: 7px;
  right: 7px;
  background-color: #555;
  position: absolute;
  transition: top 0.1s, bottom 0.1s, left 0.1s, right 0.1s, background-color 0.1s;
  border-radius: 50%;
}
span.ap-marker-container span.ap-marker.ap-marker-past {
  background-color: #bbb;
}
span.ap-marker-container span.ap-marker:hover,
span.ap-marker-container:hover span.ap-marker {
  background-color: #bbb;
  top: 11px;
  bottom: 10px;
  left: 5px;
  right: 5px;
}
span.ap-marker-container span.ap-marker-tooltip {
  visibility: hidden;
  background-color: #333;
  color: #bbb;
  text-align: center;
  padding: 2px 8px 0px 8px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
  /* Prevents the text from wrapping and makes sure the tooltip width adapts to the text length */
  font-size: 11px;
  line-height: 2em;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
span.ap-marker-container:hover span.ap-marker-tooltip {
  visibility: visible;
}
.ap-player .ap-overlay {
  z-index: 10;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ap-player .ap-overlay-start {
  cursor: pointer;
}
.ap-player .ap-overlay-start .ap-play-button {
  font-size: 0px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  color: white;
  height: 80px;
  max-height: 66%;
  margin: auto;
}
.ap-player .ap-overlay-start .ap-play-button div {
  height: 100%;
}
.ap-player .ap-overlay-start .ap-play-button div span {
  height: 100%;
  display: block;
}
.ap-player .ap-overlay-start .ap-play-button div span svg {
  height: 100%;
}
.ap-player .ap-overlay-loading .ap-loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 10px solid;
  border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  animation: ap-loader-rotation 1s linear infinite;
}
.ap-player .ap-overlay-offline span {
  font-family: Consolas, Menlo, 'Bitstream Vera Sans Mono', monospace, 'Powerline Symbols';
  font-variant-ligatures: none;
  font-size: 2em;
}
.ap-player .ap-overlay-offline span .ap-line {
  letter-spacing: normal;
  overflow: hidden;
}
.ap-player .ap-overlay-offline span .ap-line span {
  padding: 0;
  display: inline-block;
  height: 100%;
}
.ap-player .ap-overlay-error span {
  font-size: 8em;
}
@keyframes ap-loader-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ap-terminal .fg-16 {
  color: #000000;
}
.ap-terminal .bg-16 {
  background-color: #000000;
}
.ap-terminal .fg-17 {
  color: #00005f;
}
.ap-terminal .bg-17 {
  background-color: #00005f;
}
.ap-terminal .fg-18 {
  color: #000087;
}
.ap-terminal .bg-18 {
  background-color: #000087;
}
.ap-terminal .fg-19 {
  color: #0000af;
}
.ap-terminal .bg-19 {
  background-color: #0000af;
}
.ap-terminal .fg-20 {
  color: #0000d7;
}
.ap-terminal .bg-20 {
  background-color: #0000d7;
}
.ap-terminal .fg-21 {
  color: #0000ff;
}
.ap-terminal .bg-21 {
  background-color: #0000ff;
}
.ap-terminal .fg-22 {
  color: #005f00;
}
.ap-terminal .bg-22 {
  background-color: #005f00;
}
.ap-terminal .fg-23 {
  color: #005f5f;
}
.ap-terminal .bg-23 {
  background-color: #005f5f;
}
.ap-terminal .fg-24 {
  color: #005f87;
}
.ap-terminal .bg-24 {
  background-color: #005f87;
}
.ap-terminal .fg-25 {
  color: #005faf;
}
.ap-terminal .bg-25 {
  background-color: #005faf;
}
.ap-terminal .fg-26 {
  color: #005fd7;
}
.ap-terminal .bg-26 {
  background-color: #005fd7;
}
.ap-terminal .fg-27 {
  color: #005fff;
}
.ap-terminal .bg-27 {
  background-color: #005fff;
}
.ap-terminal .fg-28 {
  color: #008700;
}
.ap-terminal .bg-28 {
  background-color: #008700;
}
.ap-terminal .fg-29 {
  color: #00875f;
}
.ap-terminal .bg-29 {
  background-color: #00875f;
}
.ap-terminal .fg-30 {
  color: #008787;
}
.ap-terminal .bg-30 {
  background-color: #008787;
}
.ap-terminal .fg-31 {
  color: #0087af;
}
.ap-terminal .bg-31 {
  background-color: #0087af;
}
.ap-terminal .fg-32 {
  color: #0087d7;
}
.ap-terminal .bg-32 {
  background-color: #0087d7;
}
.ap-terminal .fg-33 {
  color: #0087ff;
}
.ap-terminal .bg-33 {
  background-color: #0087ff;
}
.ap-terminal .fg-34 {
  color: #00af00;
}
.ap-terminal .bg-34 {
  background-color: #00af00;
}
.ap-terminal .fg-35 {
  color: #00af5f;
}
.ap-terminal .bg-35 {
  background-color: #00af5f;
}
.ap-terminal .fg-36 {
  color: #00af87;
}
.ap-terminal .bg-36 {
  background-color: #00af87;
}
.ap-terminal .fg-37 {
  color: #00afaf;
}
.ap-terminal .bg-37 {
  background-color: #00afaf;
}
.ap-terminal .fg-38 {
  color: #00afd7;
}
.ap-terminal .bg-38 {
  background-color: #00afd7;
}
.ap-terminal .fg-39 {
  color: #00afff;
}
.ap-terminal .bg-39 {
  background-color: #00afff;
}
.ap-terminal .fg-40 {
  color: #00d700;
}
.ap-terminal .bg-40 {
  background-color: #00d700;
}
.ap-terminal .fg-41 {
  color: #00d75f;
}
.ap-terminal .bg-41 {
  background-color: #00d75f;
}
.ap-terminal .fg-42 {
  color: #00d787;
}
.ap-terminal .bg-42 {
  background-color: #00d787;
}
.ap-terminal .fg-43 {
  color: #00d7af;
}
.ap-terminal .bg-43 {
  background-color: #00d7af;
}
.ap-terminal .fg-44 {
  color: #00d7d7;
}
.ap-terminal .bg-44 {
  background-color: #00d7d7;
}
.ap-terminal .fg-45 {
  color: #00d7ff;
}
.ap-terminal .bg-45 {
  background-color: #00d7ff;
}
.ap-terminal .fg-46 {
  color: #00ff00;
}
.ap-terminal .bg-46 {
  background-color: #00ff00;
}
.ap-terminal .fg-47 {
  color: #00ff5f;
}
.ap-terminal .bg-47 {
  background-color: #00ff5f;
}
.ap-terminal .fg-48 {
  color: #00ff87;
}
.ap-terminal .bg-48 {
  background-color: #00ff87;
}
.ap-terminal .fg-49 {
  color: #00ffaf;
}
.ap-terminal .bg-49 {
  background-color: #00ffaf;
}
.ap-terminal .fg-50 {
  color: #00ffd7;
}
.ap-terminal .bg-50 {
  background-color: #00ffd7;
}
.ap-terminal .fg-51 {
  color: #00ffff;
}
.ap-terminal .bg-51 {
  background-color: #00ffff;
}
.ap-terminal .fg-52 {
  color: #5f0000;
}
.ap-terminal .bg-52 {
  background-color: #5f0000;
}
.ap-terminal .fg-53 {
  color: #5f005f;
}
.ap-terminal .bg-53 {
  background-color: #5f005f;
}
.ap-terminal .fg-54 {
  color: #5f0087;
}
.ap-terminal .bg-54 {
  background-color: #5f0087;
}
.ap-terminal .fg-55 {
  color: #5f00af;
}
.ap-terminal .bg-55 {
  background-color: #5f00af;
}
.ap-terminal .fg-56 {
  color: #5f00d7;
}
.ap-terminal .bg-56 {
  background-color: #5f00d7;
}
.ap-terminal .fg-57 {
  color: #5f00ff;
}
.ap-terminal .bg-57 {
  background-color: #5f00ff;
}
.ap-terminal .fg-58 {
  color: #5f5f00;
}
.ap-terminal .bg-58 {
  background-color: #5f5f00;
}
.ap-terminal .fg-59 {
  color: #5f5f5f;
}
.ap-terminal .bg-59 {
  background-color: #5f5f5f;
}
.ap-terminal .fg-60 {
  color: #5f5f87;
}
.ap-terminal .bg-60 {
  background-color: #5f5f87;
}
.ap-terminal .fg-61 {
  color: #5f5faf;
}
.ap-terminal .bg-61 {
  background-color: #5f5faf;
}
.ap-terminal .fg-62 {
  color: #5f5fd7;
}
.ap-terminal .bg-62 {
  background-color: #5f5fd7;
}
.ap-terminal .fg-63 {
  color: #5f5fff;
}
.ap-terminal .bg-63 {
  background-color: #5f5fff;
}
.ap-terminal .fg-64 {
  color: #5f8700;
}
.ap-terminal .bg-64 {
  background-color: #5f8700;
}
.ap-terminal .fg-65 {
  color: #5f875f;
}
.ap-terminal .bg-65 {
  background-color: #5f875f;
}
.ap-terminal .fg-66 {
  color: #5f8787;
}
.ap-terminal .bg-66 {
  background-color: #5f8787;
}
.ap-terminal .fg-67 {
  color: #5f87af;
}
.ap-terminal .bg-67 {
  background-color: #5f87af;
}
.ap-terminal .fg-68 {
  color: #5f87d7;
}
.ap-terminal .bg-68 {
  background-color: #5f87d7;
}
.ap-terminal .fg-69 {
  color: #5f87ff;
}
.ap-terminal .bg-69 {
  background-color: #5f87ff;
}
.ap-terminal .fg-70 {
  color: #5faf00;
}
.ap-terminal .bg-70 {
  background-color: #5faf00;
}
.ap-terminal .fg-71 {
  color: #5faf5f;
}
.ap-terminal .bg-71 {
  background-color: #5faf5f;
}
.ap-terminal .fg-72 {
  color: #5faf87;
}
.ap-terminal .bg-72 {
  background-color: #5faf87;
}
.ap-terminal .fg-73 {
  color: #5fafaf;
}
.ap-terminal .bg-73 {
  background-color: #5fafaf;
}
.ap-terminal .fg-74 {
  color: #5fafd7;
}
.ap-terminal .bg-74 {
  background-color: #5fafd7;
}
.ap-terminal .fg-75 {
  color: #5fafff;
}
.ap-terminal .bg-75 {
  background-color: #5fafff;
}
.ap-terminal .fg-76 {
  color: #5fd700;
}
.ap-terminal .bg-76 {
  background-color: #5fd700;
}
.ap-terminal .fg-77 {
  color: #5fd75f;
}
.ap-terminal .bg-77 {
  background-color: #5fd75f;
}
.ap-terminal .fg-78 {
  color: #5fd787;
}
.ap-terminal .bg-78 {
  background-color: #5fd787;
}
.ap-terminal .fg-79 {
  color: #5fd7af;
}
.ap-terminal .bg-79 {
  background-color: #5fd7af;
}
.ap-terminal .fg-80 {
  color: #5fd7d7;
}
.ap-terminal .bg-80 {
  background-color: #5fd7d7;
}
.ap-terminal .fg-81 {
  color: #5fd7ff;
}
.ap-terminal .bg-81 {
  background-color: #5fd7ff;
}
.ap-terminal .fg-82 {
  color: #5fff00;
}
.ap-terminal .bg-82 {
  background-color: #5fff00;
}
.ap-terminal .fg-83 {
  color: #5fff5f;
}
.ap-terminal .bg-83 {
  background-color: #5fff5f;
}
.ap-terminal .fg-84 {
  color: #5fff87;
}
.ap-terminal .bg-84 {
  background-color: #5fff87;
}
.ap-terminal .fg-85 {
  color: #5fffaf;
}
.ap-terminal .bg-85 {
  background-color: #5fffaf;
}
.ap-terminal .fg-86 {
  color: #5fffd7;
}
.ap-terminal .bg-86 {
  background-color: #5fffd7;
}
.ap-terminal .fg-87 {
  color: #5fffff;
}
.ap-terminal .bg-87 {
  background-color: #5fffff;
}
.ap-terminal .fg-88 {
  color: #870000;
}
.ap-terminal .bg-88 {
  background-color: #870000;
}
.ap-terminal .fg-89 {
  color: #87005f;
}
.ap-terminal .bg-89 {
  background-color: #87005f;
}
.ap-terminal .fg-90 {
  color: #870087;
}
.ap-terminal .bg-90 {
  background-color: #870087;
}
.ap-terminal .fg-91 {
  color: #8700af;
}
.ap-terminal .bg-91 {
  background-color: #8700af;
}
.ap-terminal .fg-92 {
  color: #8700d7;
}
.ap-terminal .bg-92 {
  background-color: #8700d7;
}
.ap-terminal .fg-93 {
  color: #8700ff;
}
.ap-terminal .bg-93 {
  background-color: #8700ff;
}
.ap-terminal .fg-94 {
  color: #875f00;
}
.ap-terminal .bg-94 {
  background-color: #875f00;
}
.ap-terminal .fg-95 {
  color: #875f5f;
}
.ap-terminal .bg-95 {
  background-color: #875f5f;
}
.ap-terminal .fg-96 {
  color: #875f87;
}
.ap-terminal .bg-96 {
  background-color: #875f87;
}
.ap-terminal .fg-97 {
  color: #875faf;
}
.ap-terminal .bg-97 {
  background-color: #875faf;
}
.ap-terminal .fg-98 {
  color: #875fd7;
}
.ap-terminal .bg-98 {
  background-color: #875fd7;
}
.ap-terminal .fg-99 {
  color: #875fff;
}
.ap-terminal .bg-99 {
  background-color: #875fff;
}
.ap-terminal .fg-100 {
  color: #878700;
}
.ap-terminal .bg-100 {
  background-color: #878700;
}
.ap-terminal .fg-101 {
  color: #87875f;
}
.ap-terminal .bg-101 {
  background-color: #87875f;
}
.ap-terminal .fg-102 {
  color: #878787;
}
.ap-terminal .bg-102 {
  background-color: #878787;
}
.ap-terminal .fg-103 {
  color: #8787af;
}
.ap-terminal .bg-103 {
  background-color: #8787af;
}
.ap-terminal .fg-104 {
  color: #8787d7;
}
.ap-terminal .bg-104 {
  background-color: #8787d7;
}
.ap-terminal .fg-105 {
  color: #8787ff;
}
.ap-terminal .bg-105 {
  background-color: #8787ff;
}
.ap-terminal .fg-106 {
  color: #87af00;
}
.ap-terminal .bg-106 {
  background-color: #87af00;
}
.ap-terminal .fg-107 {
  color: #87af5f;
}
.ap-terminal .bg-107 {
  background-color: #87af5f;
}
.ap-terminal .fg-108 {
  color: #87af87;
}
.ap-terminal .bg-108 {
  background-color: #87af87;
}
.ap-terminal .fg-109 {
  color: #87afaf;
}
.ap-terminal .bg-109 {
  background-color: #87afaf;
}
.ap-terminal .fg-110 {
  color: #87afd7;
}
.ap-terminal .bg-110 {
  background-color: #87afd7;
}
.ap-terminal .fg-111 {
  color: #87afff;
}
.ap-terminal .bg-111 {
  background-color: #87afff;
}
.ap-terminal .fg-112 {
  color: #87d700;
}
.ap-terminal .bg-112 {
  background-color: #87d700;
}
.ap-terminal .fg-113 {
  color: #87d75f;
}
.ap-terminal .bg-113 {
  background-color: #87d75f;
}
.ap-terminal .fg-114 {
  color: #87d787;
}
.ap-terminal .bg-114 {
  background-color: #87d787;
}
.ap-terminal .fg-115 {
  color: #87d7af;
}
.ap-terminal .bg-115 {
  background-color: #87d7af;
}
.ap-terminal .fg-116 {
  color: #87d7d7;
}
.ap-terminal .bg-116 {
  background-color: #87d7d7;
}
.ap-terminal .fg-117 {
  color: #87d7ff;
}
.ap-terminal .bg-117 {
  background-color: #87d7ff;
}
.ap-terminal .fg-118 {
  color: #87ff00;
}
.ap-terminal .bg-118 {
  background-color: #87ff00;
}
.ap-terminal .fg-119 {
  color: #87ff5f;
}
.ap-terminal .bg-119 {
  background-color: #87ff5f;
}
.ap-terminal .fg-120 {
  color: #87ff87;
}
.ap-terminal .bg-120 {
  background-color: #87ff87;
}
.ap-terminal .fg-121 {
  color: #87ffaf;
}
.ap-terminal .bg-121 {
  background-color: #87ffaf;
}
.ap-terminal .fg-122 {
  color: #87ffd7;
}
.ap-terminal .bg-122 {
  background-color: #87ffd7;
}
.ap-terminal .fg-123 {
  color: #87ffff;
}
.ap-terminal .bg-123 {
  background-color: #87ffff;
}
.ap-terminal .fg-124 {
  color: #af0000;
}
.ap-terminal .bg-124 {
  background-color: #af0000;
}
.ap-terminal .fg-125 {
  color: #af005f;
}
.ap-terminal .bg-125 {
  background-color: #af005f;
}
.ap-terminal .fg-126 {
  color: #af0087;
}
.ap-terminal .bg-126 {
  background-color: #af0087;
}
.ap-terminal .fg-127 {
  color: #af00af;
}
.ap-terminal .bg-127 {
  background-color: #af00af;
}
.ap-terminal .fg-128 {
  color: #af00d7;
}
.ap-terminal .bg-128 {
  background-color: #af00d7;
}
.ap-terminal .fg-129 {
  color: #af00ff;
}
.ap-terminal .bg-129 {
  background-color: #af00ff;
}
.ap-terminal .fg-130 {
  color: #af5f00;
}
.ap-terminal .bg-130 {
  background-color: #af5f00;
}
.ap-terminal .fg-131 {
  color: #af5f5f;
}
.ap-terminal .bg-131 {
  background-color: #af5f5f;
}
.ap-terminal .fg-132 {
  color: #af5f87;
}
.ap-terminal .bg-132 {
  background-color: #af5f87;
}
.ap-terminal .fg-133 {
  color: #af5faf;
}
.ap-terminal .bg-133 {
  background-color: #af5faf;
}
.ap-terminal .fg-134 {
  color: #af5fd7;
}
.ap-terminal .bg-134 {
  background-color: #af5fd7;
}
.ap-terminal .fg-135 {
  color: #af5fff;
}
.ap-terminal .bg-135 {
  background-color: #af5fff;
}
.ap-terminal .fg-136 {
  color: #af8700;
}
.ap-terminal .bg-136 {
  background-color: #af8700;
}
.ap-terminal .fg-137 {
  color: #af875f;
}
.ap-terminal .bg-137 {
  background-color: #af875f;
}
.ap-terminal .fg-138 {
  color: #af8787;
}
.ap-terminal .bg-138 {
  background-color: #af8787;
}
.ap-terminal .fg-139 {
  color: #af87af;
}
.ap-terminal .bg-139 {
  background-color: #af87af;
}
.ap-terminal .fg-140 {
  color: #af87d7;
}
.ap-terminal .bg-140 {
  background-color: #af87d7;
}
.ap-terminal .fg-141 {
  color: #af87ff;
}
.ap-terminal .bg-141 {
  background-color: #af87ff;
}
.ap-terminal .fg-142 {
  color: #afaf00;
}
.ap-terminal .bg-142 {
  background-color: #afaf00;
}
.ap-terminal .fg-143 {
  color: #afaf5f;
}
.ap-terminal .bg-143 {
  background-color: #afaf5f;
}
.ap-terminal .fg-144 {
  color: #afaf87;
}
.ap-terminal .bg-144 {
  background-color: #afaf87;
}
.ap-terminal .fg-145 {
  color: #afafaf;
}
.ap-terminal .bg-145 {
  background-color: #afafaf;
}
.ap-terminal .fg-146 {
  color: #afafd7;
}
.ap-terminal .bg-146 {
  background-color: #afafd7;
}
.ap-terminal .fg-147 {
  color: #afafff;
}
.ap-terminal .bg-147 {
  background-color: #afafff;
}
.ap-terminal .fg-148 {
  color: #afd700;
}
.ap-terminal .bg-148 {
  background-color: #afd700;
}
.ap-terminal .fg-149 {
  color: #afd75f;
}
.ap-terminal .bg-149 {
  background-color: #afd75f;
}
.ap-terminal .fg-150 {
  color: #afd787;
}
.ap-terminal .bg-150 {
  background-color: #afd787;
}
.ap-terminal .fg-151 {
  color: #afd7af;
}
.ap-terminal .bg-151 {
  background-color: #afd7af;
}
.ap-terminal .fg-152 {
  color: #afd7d7;
}
.ap-terminal .bg-152 {
  background-color: #afd7d7;
}
.ap-terminal .fg-153 {
  color: #afd7ff;
}
.ap-terminal .bg-153 {
  background-color: #afd7ff;
}
.ap-terminal .fg-154 {
  color: #afff00;
}
.ap-terminal .bg-154 {
  background-color: #afff00;
}
.ap-terminal .fg-155 {
  color: #afff5f;
}
.ap-terminal .bg-155 {
  background-color: #afff5f;
}
.ap-terminal .fg-156 {
  color: #afff87;
}
.ap-terminal .bg-156 {
  background-color: #afff87;
}
.ap-terminal .fg-157 {
  color: #afffaf;
}
.ap-terminal .bg-157 {
  background-color: #afffaf;
}
.ap-terminal .fg-158 {
  color: #afffd7;
}
.ap-terminal .bg-158 {
  background-color: #afffd7;
}
.ap-terminal .fg-159 {
  color: #afffff;
}
.ap-terminal .bg-159 {
  background-color: #afffff;
}
.ap-terminal .fg-160 {
  color: #d70000;
}
.ap-terminal .bg-160 {
  background-color: #d70000;
}
.ap-terminal .fg-161 {
  color: #d7005f;
}
.ap-terminal .bg-161 {
  background-color: #d7005f;
}
.ap-terminal .fg-162 {
  color: #d70087;
}
.ap-terminal .bg-162 {
  background-color: #d70087;
}
.ap-terminal .fg-163 {
  color: #d700af;
}
.ap-terminal .bg-163 {
  background-color: #d700af;
}
.ap-terminal .fg-164 {
  color: #d700d7;
}
.ap-terminal .bg-164 {
  background-color: #d700d7;
}
.ap-terminal .fg-165 {
  color: #d700ff;
}
.ap-terminal .bg-165 {
  background-color: #d700ff;
}
.ap-terminal .fg-166 {
  color: #d75f00;
}
.ap-terminal .bg-166 {
  background-color: #d75f00;
}
.ap-terminal .fg-167 {
  color: #d75f5f;
}
.ap-terminal .bg-167 {
  background-color: #d75f5f;
}
.ap-terminal .fg-168 {
  color: #d75f87;
}
.ap-terminal .bg-168 {
  background-color: #d75f87;
}
.ap-terminal .fg-169 {
  color: #d75faf;
}
.ap-terminal .bg-169 {
  background-color: #d75faf;
}
.ap-terminal .fg-170 {
  color: #d75fd7;
}
.ap-terminal .bg-170 {
  background-color: #d75fd7;
}
.ap-terminal .fg-171 {
  color: #d75fff;
}
.ap-terminal .bg-171 {
  background-color: #d75fff;
}
.ap-terminal .fg-172 {
  color: #d78700;
}
.ap-terminal .bg-172 {
  background-color: #d78700;
}
.ap-terminal .fg-173 {
  color: #d7875f;
}
.ap-terminal .bg-173 {
  background-color: #d7875f;
}
.ap-terminal .fg-174 {
  color: #d78787;
}
.ap-terminal .bg-174 {
  background-color: #d78787;
}
.ap-terminal .fg-175 {
  color: #d787af;
}
.ap-terminal .bg-175 {
  background-color: #d787af;
}
.ap-terminal .fg-176 {
  color: #d787d7;
}
.ap-terminal .bg-176 {
  background-color: #d787d7;
}
.ap-terminal .fg-177 {
  color: #d787ff;
}
.ap-terminal .bg-177 {
  background-color: #d787ff;
}
.ap-terminal .fg-178 {
  color: #d7af00;
}
.ap-terminal .bg-178 {
  background-color: #d7af00;
}
.ap-terminal .fg-179 {
  color: #d7af5f;
}
.ap-terminal .bg-179 {
  background-color: #d7af5f;
}
.ap-terminal .fg-180 {
  color: #d7af87;
}
.ap-terminal .bg-180 {
  background-color: #d7af87;
}
.ap-terminal .fg-181 {
  color: #d7afaf;
}
.ap-terminal .bg-181 {
  background-color: #d7afaf;
}
.ap-terminal .fg-182 {
  color: #d7afd7;
}
.ap-terminal .bg-182 {
  background-color: #d7afd7;
}
.ap-terminal .fg-183 {
  color: #d7afff;
}
.ap-terminal .bg-183 {
  background-color: #d7afff;
}
.ap-terminal .fg-184 {
  color: #d7d700;
}
.ap-terminal .bg-184 {
  background-color: #d7d700;
}
.ap-terminal .fg-185 {
  color: #d7d75f;
}
.ap-terminal .bg-185 {
  background-color: #d7d75f;
}
.ap-terminal .fg-186 {
  color: #d7d787;
}
.ap-terminal .bg-186 {
  background-color: #d7d787;
}
.ap-terminal .fg-187 {
  color: #d7d7af;
}
.ap-terminal .bg-187 {
  background-color: #d7d7af;
}
.ap-terminal .fg-188 {
  color: #d7d7d7;
}
.ap-terminal .bg-188 {
  background-color: #d7d7d7;
}
.ap-terminal .fg-189 {
  color: #d7d7ff;
}
.ap-terminal .bg-189 {
  background-color: #d7d7ff;
}
.ap-terminal .fg-190 {
  color: #d7ff00;
}
.ap-terminal .bg-190 {
  background-color: #d7ff00;
}
.ap-terminal .fg-191 {
  color: #d7ff5f;
}
.ap-terminal .bg-191 {
  background-color: #d7ff5f;
}
.ap-terminal .fg-192 {
  color: #d7ff87;
}
.ap-terminal .bg-192 {
  background-color: #d7ff87;
}
.ap-terminal .fg-193 {
  color: #d7ffaf;
}
.ap-terminal .bg-193 {
  background-color: #d7ffaf;
}
.ap-terminal .fg-194 {
  color: #d7ffd7;
}
.ap-terminal .bg-194 {
  background-color: #d7ffd7;
}
.ap-terminal .fg-195 {
  color: #d7ffff;
}
.ap-terminal .bg-195 {
  background-color: #d7ffff;
}
.ap-terminal .fg-196 {
  color: #ff0000;
}
.ap-terminal .bg-196 {
  background-color: #ff0000;
}
.ap-terminal .fg-197 {
  color: #ff005f;
}
.ap-terminal .bg-197 {
  background-color: #ff005f;
}
.ap-terminal .fg-198 {
  color: #ff0087;
}
.ap-terminal .bg-198 {
  background-color: #ff0087;
}
.ap-terminal .fg-199 {
  color: #ff00af;
}
.ap-terminal .bg-199 {
  background-color: #ff00af;
}
.ap-terminal .fg-200 {
  color: #ff00d7;
}
.ap-terminal .bg-200 {
  background-color: #ff00d7;
}
.ap-terminal .fg-201 {
  color: #ff00ff;
}
.ap-terminal .bg-201 {
  background-color: #ff00ff;
}
.ap-terminal .fg-202 {
  color: #ff5f00;
}
.ap-terminal .bg-202 {
  background-color: #ff5f00;
}
.ap-terminal .fg-203 {
  color: #ff5f5f;
}
.ap-terminal .bg-203 {
  background-color: #ff5f5f;
}
.ap-terminal .fg-204 {
  color: #ff5f87;
}
.ap-terminal .bg-204 {
  background-color: #ff5f87;
}
.ap-terminal .fg-205 {
  color: #ff5faf;
}
.ap-terminal .bg-205 {
  background-color: #ff5faf;
}
.ap-terminal .fg-206 {
  color: #ff5fd7;
}
.ap-terminal .bg-206 {
  background-color: #ff5fd7;
}
.ap-terminal .fg-207 {
  color: #ff5fff;
}
.ap-terminal .bg-207 {
  background-color: #ff5fff;
}
.ap-terminal .fg-208 {
  color: #ff8700;
}
.ap-terminal .bg-208 {
  background-color: #ff8700;
}
.ap-terminal .fg-209 {
  color: #ff875f;
}
.ap-terminal .bg-209 {
  background-color: #ff875f;
}
.ap-terminal .fg-210 {
  color: #ff8787;
}
.ap-terminal .bg-210 {
  background-color: #ff8787;
}
.ap-terminal .fg-211 {
  color: #ff87af;
}
.ap-terminal .bg-211 {
  background-color: #ff87af;
}
.ap-terminal .fg-212 {
  color: #ff87d7;
}
.ap-terminal .bg-212 {
  background-color: #ff87d7;
}
.ap-terminal .fg-213 {
  color: #ff87ff;
}
.ap-terminal .bg-213 {
  background-color: #ff87ff;
}
.ap-terminal .fg-214 {
  color: #ffaf00;
}
.ap-terminal .bg-214 {
  background-color: #ffaf00;
}
.ap-terminal .fg-215 {
  color: #ffaf5f;
}
.ap-terminal .bg-215 {
  background-color: #ffaf5f;
}
.ap-terminal .fg-216 {
  color: #ffaf87;
}
.ap-terminal .bg-216 {
  background-color: #ffaf87;
}
.ap-terminal .fg-217 {
  color: #ffafaf;
}
.ap-terminal .bg-217 {
  background-color: #ffafaf;
}
.ap-terminal .fg-218 {
  color: #ffafd7;
}
.ap-terminal .bg-218 {
  background-color: #ffafd7;
}
.ap-terminal .fg-219 {
  color: #ffafff;
}
.ap-terminal .bg-219 {
  background-color: #ffafff;
}
.ap-terminal .fg-220 {
  color: #ffd700;
}
.ap-terminal .bg-220 {
  background-color: #ffd700;
}
.ap-terminal .fg-221 {
  color: #ffd75f;
}
.ap-terminal .bg-221 {
  background-color: #ffd75f;
}
.ap-terminal .fg-222 {
  color: #ffd787;
}
.ap-terminal .bg-222 {
  background-color: #ffd787;
}
.ap-terminal .fg-223 {
  color: #ffd7af;
}
.ap-terminal .bg-223 {
  background-color: #ffd7af;
}
.ap-terminal .fg-224 {
  color: #ffd7d7;
}
.ap-terminal .bg-224 {
  background-color: #ffd7d7;
}
.ap-terminal .fg-225 {
  color: #ffd7ff;
}
.ap-terminal .bg-225 {
  background-color: #ffd7ff;
}
.ap-terminal .fg-226 {
  color: #ffff00;
}
.ap-terminal .bg-226 {
  background-color: #ffff00;
}
.ap-terminal .fg-227 {
  color: #ffff5f;
}
.ap-terminal .bg-227 {
  background-color: #ffff5f;
}
.ap-terminal .fg-228 {
  color: #ffff87;
}
.ap-terminal .bg-228 {
  background-color: #ffff87;
}
.ap-terminal .fg-229 {
  color: #ffffaf;
}
.ap-terminal .bg-229 {
  background-color: #ffffaf;
}
.ap-terminal .fg-230 {
  color: #ffffd7;
}
.ap-terminal .bg-230 {
  background-color: #ffffd7;
}
.ap-terminal .fg-231 {
  color: #ffffff;
}
.ap-terminal .bg-231 {
  background-color: #ffffff;
}
.ap-terminal .fg-232 {
  color: #080808;
}
.ap-terminal .bg-232 {
  background-color: #080808;
}
.ap-terminal .fg-233 {
  color: #121212;
}
.ap-terminal .bg-233 {
  background-color: #121212;
}
.ap-terminal .fg-234 {
  color: #1c1c1c;
}
.ap-terminal .bg-234 {
  background-color: #1c1c1c;
}
.ap-terminal .fg-235 {
  color: #262626;
}
.ap-terminal .bg-235 {
  background-color: #262626;
}
.ap-terminal .fg-236 {
  color: #303030;
}
.ap-terminal .bg-236 {
  background-color: #303030;
}
.ap-terminal .fg-237 {
  color: #3a3a3a;
}
.ap-terminal .bg-237 {
  background-color: #3a3a3a;
}
.ap-terminal .fg-238 {
  color: #444444;
}
.ap-terminal .bg-238 {
  background-color: #444444;
}
.ap-terminal .fg-239 {
  color: #4e4e4e;
}
.ap-terminal .bg-239 {
  background-color: #4e4e4e;
}
.ap-terminal .fg-240 {
  color: #585858;
}
.ap-terminal .bg-240 {
  background-color: #585858;
}
.ap-terminal .fg-241 {
  color: #626262;
}
.ap-terminal .bg-241 {
  background-color: #626262;
}
.ap-terminal .fg-242 {
  color: #6c6c6c;
}
.ap-terminal .bg-242 {
  background-color: #6c6c6c;
}
.ap-terminal .fg-243 {
  color: #767676;
}
.ap-terminal .bg-243 {
  background-color: #767676;
}
.ap-terminal .fg-244 {
  color: #808080;
}
.ap-terminal .bg-244 {
  background-color: #808080;
}
.ap-terminal .fg-245 {
  color: #8a8a8a;
}
.ap-terminal .bg-245 {
  background-color: #8a8a8a;
}
.ap-terminal .fg-246 {
  color: #949494;
}
.ap-terminal .bg-246 {
  background-color: #949494;
}
.ap-terminal .fg-247 {
  color: #9e9e9e;
}
.ap-terminal .bg-247 {
  background-color: #9e9e9e;
}
.ap-terminal .fg-248 {
  color: #a8a8a8;
}
.ap-terminal .bg-248 {
  background-color: #a8a8a8;
}
.ap-terminal .fg-249 {
  color: #b2b2b2;
}
.ap-terminal .bg-249 {
  background-color: #b2b2b2;
}
.ap-terminal .fg-250 {
  color: #bcbcbc;
}
.ap-terminal .bg-250 {
  background-color: #bcbcbc;
}
.ap-terminal .fg-251 {
  color: #c6c6c6;
}
.ap-terminal .bg-251 {
  background-color: #c6c6c6;
}
.ap-terminal .fg-252 {
  color: #d0d0d0;
}
.ap-terminal .bg-252 {
  background-color: #d0d0d0;
}
.ap-terminal .fg-253 {
  color: #dadada;
}
.ap-terminal .bg-253 {
  background-color: #dadada;
}
.ap-terminal .fg-254 {
  color: #e4e4e4;
}
.ap-terminal .bg-254 {
  background-color: #e4e4e4;
}
.ap-terminal .fg-255 {
  color: #eeeeee;
}
.ap-terminal .bg-255 {
  background-color: #eeeeee;
}
.asciinema-theme-asciinema {
  background-color: #121314;
}
.asciinema-theme-asciinema pre.ap-terminal {
  color: #CCCCCC;
  background-color: #121314;
  border-color: #121314;
}
.asciinema-theme-asciinema .fg-default {
  color: #CCCCCC;
}
.asciinema-theme-asciinema .fg-bg {
  color: #121314;
}
.asciinema-theme-asciinema .bg-default {
  background-color: #121314;
}
.asciinema-theme-asciinema .bg-fg {
  background-color: #CCCCCC;
}
.asciinema-theme-asciinema .fg-0 {
  color: hsl(0, 0%, 0%);
}
.asciinema-theme-asciinema .bg-0 {
  background-color: hsl(0, 0%, 0%);
}
.asciinema-theme-asciinema .fg-1 {
  color: hsl(343, 70%, 55%);
}
.asciinema-theme-asciinema .bg-1 {
  background-color: hsl(343, 70%, 55%);
}
.asciinema-theme-asciinema .fg-2 {
  color: hsl(103, 70%, 44%);
}
.asciinema-theme-asciinema .bg-2 {
  background-color: hsl(103, 70%, 44%);
}
.asciinema-theme-asciinema .fg-3 {
  color: hsl(43, 70%, 55%);
}
.asciinema-theme-asciinema .bg-3 {
  background-color: hsl(43, 70%, 55%);
}
.asciinema-theme-asciinema .fg-4 {
  color: hsl(193, 70%, 49.5%);
}
.asciinema-theme-asciinema .bg-4 {
  background-color: hsl(193, 70%, 49.5%);
}
.asciinema-theme-asciinema .fg-5 {
  color: hsl(283, 70%, 60.5%);
}
.asciinema-theme-asciinema .bg-5 {
  background-color: hsl(283, 70%, 60.5%);
}
.asciinema-theme-asciinema .fg-6 {
  color: hsl(163, 70%, 60.5%);
}
.asciinema-theme-asciinema .bg-6 {
  background-color: hsl(163, 70%, 60.5%);
}
.asciinema-theme-asciinema .fg-7 {
  color: hsl(0, 0%, 85%);
}
.asciinema-theme-asciinema .bg-7 {
  background-color: hsl(0, 0%, 85%);
}
.asciinema-theme-asciinema .fg-8 {
  color: hsl(0, 0%, 30%);
}
.asciinema-theme-asciinema .bg-8 {
  background-color: hsl(0, 0%, 30%);
}
.asciinema-theme-asciinema .fg-9 {
  color: hsl(343, 70%, 55%);
}
.asciinema-theme-asciinema .bg-9 {
  background-color: hsl(343, 70%, 55%);
}
.asciinema-theme-asciinema .fg-10 {
  color: hsl(103, 70%, 44%);
}
.asciinema-theme-asciinema .bg-10 {
  background-color: hsl(103, 70%, 44%);
}
.asciinema-theme-asciinema .fg-11 {
  color: hsl(43, 70%, 55%);
}
.asciinema-theme-asciinema .bg-11 {
  background-color: hsl(43, 70%, 55%);
}
.asciinema-theme-asciinema .fg-12 {
  color: hsl(193, 70%, 49.5%);
}
.asciinema-theme-asciinema .bg-12 {
  background-color: hsl(193, 70%, 49.5%);
}
.asciinema-theme-asciinema .fg-13 {
  color: hsl(283, 70%, 60.5%);
}
.asciinema-theme-asciinema .bg-13 {
  background-color: hsl(283, 70%, 60.5%);
}
.asciinema-theme-asciinema .fg-14 {
  color: hsl(163, 70%, 60.5%);
}
.asciinema-theme-asciinema .bg-14 {
  background-color: hsl(163, 70%, 60.5%);
}
.asciinema-theme-asciinema .fg-15 {
  color: hsl(0, 0%, 100%);
}
.asciinema-theme-asciinema .bg-15 {
  background-color: hsl(0, 0%, 100%);
}
.asciinema-theme-asciinema .ap-overlay-loading .ap-loader {
  border-color: rgba(204, 204, 204, 0.3) rgba(204, 204, 204, 0.5) rgba(204, 204, 204, 0.7) #cccccc;
}
.asciinema-theme-asciinema .fg-8,
.asciinema-theme-asciinema .fg-9,
.asciinema-theme-asciinema .fg-10,
.asciinema-theme-asciinema .fg-11,
.asciinema-theme-asciinema .fg-12,
.asciinema-theme-asciinema .fg-13,
.asciinema-theme-asciinema .fg-14,
.asciinema-theme-asciinema .fg-15 {
  font-weight: bold;
}
/*
  Based on Dracula: https://draculatheme.com
 */
.asciinema-theme-dracula {
  background-color: #282a36;
}
.asciinema-theme-dracula pre.ap-terminal {
  color: #f8f8f2;
  background-color: #282a36;
  border-color: #282a36;
}
.asciinema-theme-dracula .fg-default {
  color: #f8f8f2;
}
.asciinema-theme-dracula .fg-bg {
  color: #282a36;
}
.asciinema-theme-dracula .bg-default {
  background-color: #282a36;
}
.asciinema-theme-dracula .bg-fg {
  background-color: #f8f8f2;
}
.asciinema-theme-dracula .fg-0 {
  color: #21222c;
}
.asciinema-theme-dracula .bg-0 {
  background-color: #21222c;
}
.asciinema-theme-dracula .fg-1 {
  color: #ff5555;
}
.asciinema-theme-dracula .bg-1 {
  background-color: #ff5555;
}
.asciinema-theme-dracula .fg-2 {
  color: #50fa7b;
}
.asciinema-theme-dracula .bg-2 {
  background-color: #50fa7b;
}
.asciinema-theme-dracula .fg-3 {
  color: #f1fa8c;
}
.asciinema-theme-dracula .bg-3 {
  background-color: #f1fa8c;
}
.asciinema-theme-dracula .fg-4 {
  color: #bd93f9;
}
.asciinema-theme-dracula .bg-4 {
  background-color: #bd93f9;
}
.asciinema-theme-dracula .fg-5 {
  color: #ff79c6;
}
.asciinema-theme-dracula .bg-5 {
  background-color: #ff79c6;
}
.asciinema-theme-dracula .fg-6 {
  color: #8be9fd;
}
.asciinema-theme-dracula .bg-6 {
  background-color: #8be9fd;
}
.asciinema-theme-dracula .fg-7 {
  color: #f8f8f2;
}
.asciinema-theme-dracula .bg-7 {
  background-color: #f8f8f2;
}
.asciinema-theme-dracula .fg-8 {
  color: #6272a4;
}
.asciinema-theme-dracula .bg-8 {
  background-color: #6272a4;
}
.asciinema-theme-dracula .fg-9 {
  color: #ff6e6e;
}
.asciinema-theme-dracula .bg-9 {
  background-color: #ff6e6e;
}
.asciinema-theme-dracula .fg-10 {
  color: #69ff94;
}
.asciinema-theme-dracula .bg-10 {
  background-color: #69ff94;
}
.asciinema-theme-dracula .fg-11 {
  color: #ffffa5;
}
.asciinema-theme-dracula .bg-11 {
  background-color: #ffffa5;
}
.asciinema-theme-dracula .fg-12 {
  color: #d6acff;
}
.asciinema-theme-dracula .bg-12 {
  background-color: #d6acff;
}
.asciinema-theme-dracula .fg-13 {
  color: #ff92df;
}
.asciinema-theme-dracula .bg-13 {
  background-color: #ff92df;
}
.asciinema-theme-dracula .fg-14 {
  color: #a4ffff;
}
.asciinema-theme-dracula .bg-14 {
  background-color: #a4ffff;
}
.asciinema-theme-dracula .fg-15 {
  color: #ffffff;
}
.asciinema-theme-dracula .bg-15 {
  background-color: #ffffff;
}
.asciinema-theme-dracula .ap-overlay-loading .ap-loader {
  border-color: rgba(248, 248, 242, 0.3) rgba(248, 248, 242, 0.5) rgba(248, 248, 242, 0.7) #f8f8f2;
}
.asciinema-theme-dracula .fg-8,
.asciinema-theme-dracula .fg-9,
.asciinema-theme-dracula .fg-10,
.asciinema-theme-dracula .fg-11,
.asciinema-theme-dracula .fg-12,
.asciinema-theme-dracula .fg-13,
.asciinema-theme-dracula .fg-14,
.asciinema-theme-dracula .fg-15 {
  font-weight: bold;
}
/* Based on Monokai from base16 collection - https://github.com/chriskempson/base16 */
.asciinema-theme-monokai {
  background-color: #272822;
}
.asciinema-theme-monokai pre.ap-terminal {
  color: #f8f8f2;
  background-color: #272822;
  border-color: #272822;
}
.asciinema-theme-monokai .fg-default {
  color: #f8f8f2;
}
.asciinema-theme-monokai .fg-bg {
  color: #272822;
}
.asciinema-theme-monokai .bg-default {
  background-color: #272822;
}
.asciinema-theme-monokai .bg-fg {
  background-color: #f8f8f2;
}
.asciinema-theme-monokai .fg-0 {
  color: #272822;
}
.asciinema-theme-monokai .bg-0 {
  background-color: #272822;
}
.asciinema-theme-monokai .fg-1 {
  color: #f92672;
}
.asciinema-theme-monokai .bg-1 {
  background-color: #f92672;
}
.asciinema-theme-monokai .fg-2 {
  color: #a6e22e;
}
.asciinema-theme-monokai .bg-2 {
  background-color: #a6e22e;
}
.asciinema-theme-monokai .fg-3 {
  color: #f4bf75;
}
.asciinema-theme-monokai .bg-3 {
  background-color: #f4bf75;
}
.asciinema-theme-monokai .fg-4 {
  color: #66d9ef;
}
.asciinema-theme-monokai .bg-4 {
  background-color: #66d9ef;
}
.asciinema-theme-monokai .fg-5 {
  color: #ae81ff;
}
.asciinema-theme-monokai .bg-5 {
  background-color: #ae81ff;
}
.asciinema-theme-monokai .fg-6 {
  color: #a1efe4;
}
.asciinema-theme-monokai .bg-6 {
  background-color: #a1efe4;
}
.asciinema-theme-monokai .fg-7 {
  color: #f8f8f2;
}
.asciinema-theme-monokai .bg-7 {
  background-color: #f8f8f2;
}
.asciinema-theme-monokai .fg-8 {
  color: #75715e;
}
.asciinema-theme-monokai .bg-8 {
  background-color: #75715e;
}
.asciinema-theme-monokai .fg-9 {
  color: #f92672;
}
.asciinema-theme-monokai .bg-9 {
  background-color: #f92672;
}
.asciinema-theme-monokai .fg-10 {
  color: #a6e22e;
}
.asciinema-theme-monokai .bg-10 {
  background-color: #a6e22e;
}
.asciinema-theme-monokai .fg-11 {
  color: #f4bf75;
}
.asciinema-theme-monokai .bg-11 {
  background-color: #f4bf75;
}
.asciinema-theme-monokai .fg-12 {
  color: #66d9ef;
}
.asciinema-theme-monokai .bg-12 {
  background-color: #66d9ef;
}
.asciinema-theme-monokai .fg-13 {
  color: #ae81ff;
}
.asciinema-theme-monokai .bg-13 {
  background-color: #ae81ff;
}
.asciinema-theme-monokai .fg-14 {
  color: #a1efe4;
}
.asciinema-theme-monokai .bg-14 {
  background-color: #a1efe4;
}
.asciinema-theme-monokai .fg-15 {
  color: #f9f8f5;
}
.asciinema-theme-monokai .bg-15 {
  background-color: #f9f8f5;
}
.asciinema-theme-monokai .ap-overlay-loading .ap-loader {
  border-color: rgba(248, 248, 242, 0.3) rgba(248, 248, 242, 0.5) rgba(248, 248, 242, 0.7) #f8f8f2;
}
.asciinema-theme-monokai .fg-8,
.asciinema-theme-monokai .fg-9,
.asciinema-theme-monokai .fg-10,
.asciinema-theme-monokai .fg-11,
.asciinema-theme-monokai .fg-12,
.asciinema-theme-monokai .fg-13,
.asciinema-theme-monokai .fg-14,
.asciinema-theme-monokai .fg-15 {
  font-weight: bold;
}
/*
  Based on Nord: https://github.com/arcticicestudio/nord
  Via: https://github.com/neilotoole/asciinema-theme-nord
 */
.asciinema-theme-nord {
  background-color: #2E3440;
}
.asciinema-theme-nord pre.ap-terminal {
  color: #ECEFF4;
  background-color: #2E3440;
  border-color: #2E3440;
}
.asciinema-theme-nord .fg-default {
  color: #ECEFF4;
}
.asciinema-theme-nord .fg-bg {
  color: #2E3440;
}
.asciinema-theme-nord .bg-default {
  background-color: #2E3440;
}
.asciinema-theme-nord .bg-fg {
  background-color: #ECEFF4;
}
.asciinema-theme-nord .fg-0 {
  color: #3B4252;
}
.asciinema-theme-nord .bg-0 {
  background-color: #3B4252;
}
.asciinema-theme-nord .fg-1 {
  color: #BF616A;
}
.asciinema-theme-nord .bg-1 {
  background-color: #BF616A;
}
.asciinema-theme-nord .fg-2 {
  color: #A3BE8C;
}
.asciinema-theme-nord .bg-2 {
  background-color: #A3BE8C;
}
.asciinema-theme-nord .fg-3 {
  color: #EBCB8B;
}
.asciinema-theme-nord .bg-3 {
  background-color: #EBCB8B;
}
.asciinema-theme-nord .fg-4 {
  color: #81A1C1;
}
.asciinema-theme-nord .bg-4 {
  background-color: #81A1C1;
}
.asciinema-theme-nord .fg-5 {
  color: #B48EAD;
}
.asciinema-theme-nord .bg-5 {
  background-color: #B48EAD;
}
.asciinema-theme-nord .fg-6 {
  color: #88C0D0;
}
.asciinema-theme-nord .bg-6 {
  background-color: #88C0D0;
}
.asciinema-theme-nord .fg-7 {
  color: #ECEFF4;
}
.asciinema-theme-nord .bg-7 {
  background-color: #ECEFF4;
}
.asciinema-theme-nord .fg-8 {
  color: #3B4252;
}
.asciinema-theme-nord .bg-8 {
  background-color: #3B4252;
}
.asciinema-theme-nord .fg-9 {
  color: #BF616A;
}
.asciinema-theme-nord .bg-9 {
  background-color: #BF616A;
}
.asciinema-theme-nord .fg-10 {
  color: #A3BE8C;
}
.asciinema-theme-nord .bg-10 {
  background-color: #A3BE8C;
}
.asciinema-theme-nord .fg-11 {
  color: #EBCB8B;
}
.asciinema-theme-nord .bg-11 {
  background-color: #EBCB8B;
}
.asciinema-theme-nord .fg-12 {
  color: #81A1C1;
}
.asciinema-theme-nord .bg-12 {
  background-color: #81A1C1;
}
.asciinema-theme-nord .fg-13 {
  color: #B48EAD;
}
.asciinema-theme-nord .bg-13 {
  background-color: #B48EAD;
}
.asciinema-theme-nord .fg-14 {
  color: #88C0D0;
}
.asciinema-theme-nord .bg-14 {
  background-color: #88C0D0;
}
.asciinema-theme-nord .fg-15 {
  color: #ECEFF4;
}
.asciinema-theme-nord .bg-15 {
  background-color: #ECEFF4;
}
.asciinema-theme-nord .ap-overlay-loading .ap-loader {
  border-color: rgba(236, 239, 244, 0.3) rgba(236, 239, 244, 0.5) rgba(236, 239, 244, 0.7) #eceff4;
}
.asciinema-theme-nord .fg-8,
.asciinema-theme-nord .fg-9,
.asciinema-theme-nord .fg-10,
.asciinema-theme-nord .fg-11,
.asciinema-theme-nord .fg-12,
.asciinema-theme-nord .fg-13,
.asciinema-theme-nord .fg-14,
.asciinema-theme-nord .fg-15 {
  font-weight: bold;
}
.asciinema-theme-seti {
  background-color: #111213;
}
.asciinema-theme-seti pre.ap-terminal {
  color: #cacecd;
  background-color: #111213;
  border-color: #111213;
}
.asciinema-theme-seti .fg-default {
  color: #cacecd;
}
.asciinema-theme-seti .fg-bg {
  color: #111213;
}
.asciinema-theme-seti .bg-default {
  background-color: #111213;
}
.asciinema-theme-seti .bg-fg {
  background-color: #cacecd;
}
.asciinema-theme-seti .fg-0 {
  color: #323232;
}
.asciinema-theme-seti .bg-0 {
  background-color: #323232;
}
.asciinema-theme-seti .fg-1 {
  color: #c22832;
}
.asciinema-theme-seti .bg-1 {
  background-color: #c22832;
}
.asciinema-theme-seti .fg-2 {
  color: #8ec43d;
}
.asciinema-theme-seti .bg-2 {
  background-color: #8ec43d;
}
.asciinema-theme-seti .fg-3 {
  color: #e0c64f;
}
.asciinema-theme-seti .bg-3 {
  background-color: #e0c64f;
}
.asciinema-theme-seti .fg-4 {
  color: #43a5d5;
}
.asciinema-theme-seti .bg-4 {
  background-color: #43a5d5;
}
.asciinema-theme-seti .fg-5 {
  color: #8b57b5;
}
.asciinema-theme-seti .bg-5 {
  background-color: #8b57b5;
}
.asciinema-theme-seti .fg-6 {
  color: #8ec43d;
}
.asciinema-theme-seti .bg-6 {
  background-color: #8ec43d;
}
.asciinema-theme-seti .fg-7 {
  color: #eeeeee;
}
.asciinema-theme-seti .bg-7 {
  background-color: #eeeeee;
}
.asciinema-theme-seti .fg-8 {
  color: #323232;
}
.asciinema-theme-seti .bg-8 {
  background-color: #323232;
}
.asciinema-theme-seti .fg-9 {
  color: #c22832;
}
.asciinema-theme-seti .bg-9 {
  background-color: #c22832;
}
.asciinema-theme-seti .fg-10 {
  color: #8ec43d;
}
.asciinema-theme-seti .bg-10 {
  background-color: #8ec43d;
}
.asciinema-theme-seti .fg-11 {
  color: #e0c64f;
}
.asciinema-theme-seti .bg-11 {
  background-color: #e0c64f;
}
.asciinema-theme-seti .fg-12 {
  color: #43a5d5;
}
.asciinema-theme-seti .bg-12 {
  background-color: #43a5d5;
}
.asciinema-theme-seti .fg-13 {
  color: #8b57b5;
}
.asciinema-theme-seti .bg-13 {
  background-color: #8b57b5;
}
.asciinema-theme-seti .fg-14 {
  color: #8ec43d;
}
.asciinema-theme-seti .bg-14 {
  background-color: #8ec43d;
}
.asciinema-theme-seti .fg-15 {
  color: #ffffff;
}
.asciinema-theme-seti .bg-15 {
  background-color: #ffffff;
}
.asciinema-theme-seti .ap-overlay-loading .ap-loader {
  border-color: rgba(202, 206, 205, 0.3) rgba(202, 206, 205, 0.5) rgba(202, 206, 205, 0.7) #cacecd;
}
.asciinema-theme-seti .fg-8,
.asciinema-theme-seti .fg-9,
.asciinema-theme-seti .fg-10,
.asciinema-theme-seti .fg-11,
.asciinema-theme-seti .fg-12,
.asciinema-theme-seti .fg-13,
.asciinema-theme-seti .fg-14,
.asciinema-theme-seti .fg-15 {
  font-weight: bold;
}
.asciinema-theme-solarized-dark {
  background-color: #002b36;
}
.asciinema-theme-solarized-dark pre.ap-terminal {
  color: #839496;
  background-color: #002b36;
  border-color: #002b36;
}
.asciinema-theme-solarized-dark .fg-default {
  color: #839496;
}
.asciinema-theme-solarized-dark .fg-bg {
  color: #002b36;
}
.asciinema-theme-solarized-dark .bg-default {
  background-color: #002b36;
}
.asciinema-theme-solarized-dark .bg-fg {
  background-color: #839496;
}
.asciinema-theme-solarized-dark .fg-0 {
  color: #073642;
}
.asciinema-theme-solarized-dark .bg-0 {
  background-color: #073642;
}
.asciinema-theme-solarized-dark .fg-1 {
  color: #dc322f;
}
.asciinema-theme-solarized-dark .bg-1 {
  background-color: #dc322f;
}
.asciinema-theme-solarized-dark .fg-2 {
  color: #859900;
}
.asciinema-theme-solarized-dark .bg-2 {
  background-color: #859900;
}
.asciinema-theme-solarized-dark .fg-3 {
  color: #b58900;
}
.asciinema-theme-solarized-dark .bg-3 {
  background-color: #b58900;
}
.asciinema-theme-solarized-dark .fg-4 {
  color: #268bd2;
}
.asciinema-theme-solarized-dark .bg-4 {
  background-color: #268bd2;
}
.asciinema-theme-solarized-dark .fg-5 {
  color: #d33682;
}
.asciinema-theme-solarized-dark .bg-5 {
  background-color: #d33682;
}
.asciinema-theme-solarized-dark .fg-6 {
  color: #2aa198;
}
.asciinema-theme-solarized-dark .bg-6 {
  background-color: #2aa198;
}
.asciinema-theme-solarized-dark .fg-7 {
  color: #eee8d5;
}
.asciinema-theme-solarized-dark .bg-7 {
  background-color: #eee8d5;
}
.asciinema-theme-solarized-dark .fg-8 {
  color: #002b36;
}
.asciinema-theme-solarized-dark .bg-8 {
  background-color: #002b36;
}
.asciinema-theme-solarized-dark .fg-9 {
  color: #cb4b16;
}
.asciinema-theme-solarized-dark .bg-9 {
  background-color: #cb4b16;
}
.asciinema-theme-solarized-dark .fg-10 {
  color: #586e75;
}
.asciinema-theme-solarized-dark .bg-10 {
  background-color: #586e75;
}
.asciinema-theme-solarized-dark .fg-11 {
  color: #657b83;
}
.asciinema-theme-solarized-dark .bg-11 {
  background-color: #657b83;
}
.asciinema-theme-solarized-dark .fg-12 {
  color: #839496;
}
.asciinema-theme-solarized-dark .bg-12 {
  background-color: #839496;
}
.asciinema-theme-solarized-dark .fg-13 {
  color: #6c71c4;
}
.asciinema-theme-solarized-dark .bg-13 {
  background-color: #6c71c4;
}
.asciinema-theme-solarized-dark .fg-14 {
  color: #93a1a1;
}
.asciinema-theme-solarized-dark .bg-14 {
  background-color: #93a1a1;
}
.asciinema-theme-solarized-dark .fg-15 {
  color: #fdf6e3;
}
.asciinema-theme-solarized-dark .bg-15 {
  background-color: #fdf6e3;
}
.asciinema-theme-solarized-dark .ap-overlay-loading .ap-loader {
  border-color: rgba(131, 148, 150, 0.3) rgba(131, 148, 150, 0.5) rgba(131, 148, 150, 0.7) #839496;
}
.asciinema-theme-solarized-light {
  background-color: #fdf6e3;
}
.asciinema-theme-solarized-light pre.ap-terminal {
  color: #657b83;
  background-color: #fdf6e3;
  border-color: #fdf6e3;
}
.asciinema-theme-solarized-light .fg-default {
  color: #657b83;
}
.asciinema-theme-solarized-light .fg-bg {
  color: #fdf6e3;
}
.asciinema-theme-solarized-light .bg-default {
  background-color: #fdf6e3;
}
.asciinema-theme-solarized-light .bg-fg {
  background-color: #657b83;
}
.asciinema-theme-solarized-light .fg-0 {
  color: #073642;
}
.asciinema-theme-solarized-light .bg-0 {
  background-color: #073642;
}
.asciinema-theme-solarized-light .fg-1 {
  color: #dc322f;
}
.asciinema-theme-solarized-light .bg-1 {
  background-color: #dc322f;
}
.asciinema-theme-solarized-light .fg-2 {
  color: #859900;
}
.asciinema-theme-solarized-light .bg-2 {
  background-color: #859900;
}
.asciinema-theme-solarized-light .fg-3 {
  color: #b58900;
}
.asciinema-theme-solarized-light .bg-3 {
  background-color: #b58900;
}
.asciinema-theme-solarized-light .fg-4 {
  color: #268bd2;
}
.asciinema-theme-solarized-light .bg-4 {
  background-color: #268bd2;
}
.asciinema-theme-solarized-light .fg-5 {
  color: #d33682;
}
.asciinema-theme-solarized-light .bg-5 {
  background-color: #d33682;
}
.asciinema-theme-solarized-light .fg-6 {
  color: #2aa198;
}
.asciinema-theme-solarized-light .bg-6 {
  background-color: #2aa198;
}
.asciinema-theme-solarized-light .fg-7 {
  color: #eee8d5;
}
.asciinema-theme-solarized-light .bg-7 {
  background-color: #eee8d5;
}
.asciinema-theme-solarized-light .fg-8 {
  color: #002b36;
}
.asciinema-theme-solarized-light .bg-8 {
  background-color: #002b36;
}
.asciinema-theme-solarized-light .fg-9 {
  color: #cb4b16;
}
.asciinema-theme-solarized-light .bg-9 {
  background-color: #cb4b16;
}
.asciinema-theme-solarized-light .fg-10 {
  color: #586e75;
}
.asciinema-theme-solarized-light .bg-10 {
  background-color: #586e75;
}
.asciinema-theme-solarized-light .fg-11 {
  color: #657c83;
}
.asciinema-theme-solarized-light .bg-11 {
  background-color: #657c83;
}
.asciinema-theme-solarized-light .fg-12 {
  color: #839496;
}
.asciinema-theme-solarized-light .bg-12 {
  background-color: #839496;
}
.asciinema-theme-solarized-light .fg-13 {
  color: #6c71c4;
}
.asciinema-theme-solarized-light .bg-13 {
  background-color: #6c71c4;
}
.asciinema-theme-solarized-light .fg-14 {
  color: #93a1a1;
}
.asciinema-theme-solarized-light .bg-14 {
  background-color: #93a1a1;
}
.asciinema-theme-solarized-light .fg-15 {
  color: #fdf6e3;
}
.asciinema-theme-solarized-light .bg-15 {
  background-color: #fdf6e3;
}
.asciinema-theme-solarized-light .ap-overlay-loading .ap-loader {
  border-color: rgba(101, 123, 131, 0.3) rgba(101, 123, 131, 0.5) rgba(101, 123, 131, 0.7) #657b83;
}
.asciinema-theme-solarized-light .ap-overlay-start .ap-play-button svg .ap-play-btn-fill {
  fill: #dc322f;
}
.asciinema-theme-solarized-light .ap-overlay-start .ap-play-button svg .ap-play-btn-stroke {
  stroke: #dc322f;
}
.asciinema-theme-tango {
  background-color: #121314;
}
.asciinema-theme-tango pre.ap-terminal {
  color: #CCCCCC;
  background-color: #121314;
  border-color: #121314;
}
.asciinema-theme-tango .fg-default {
  color: #CCCCCC;
}
.asciinema-theme-tango .fg-bg {
  color: #121314;
}
.asciinema-theme-tango .bg-default {
  background-color: #121314;
}
.asciinema-theme-tango .bg-fg {
  background-color: #CCCCCC;
}
.asciinema-theme-tango .fg-0 {
  color: #000000;
}
.asciinema-theme-tango .bg-0 {
  background-color: #000000;
}
.asciinema-theme-tango .fg-1 {
  color: #CC0000;
}
.asciinema-theme-tango .bg-1 {
  background-color: #CC0000;
}
.asciinema-theme-tango .fg-2 {
  color: #4E9A06;
}
.asciinema-theme-tango .bg-2 {
  background-color: #4E9A06;
}
.asciinema-theme-tango .fg-3 {
  color: #C4A000;
}
.asciinema-theme-tango .bg-3 {
  background-color: #C4A000;
}
.asciinema-theme-tango .fg-4 {
  color: #3465A4;
}
.asciinema-theme-tango .bg-4 {
  background-color: #3465A4;
}
.asciinema-theme-tango .fg-5 {
  color: #75507B;
}
.asciinema-theme-tango .bg-5 {
  background-color: #75507B;
}
.asciinema-theme-tango .fg-6 {
  color: #06989A;
}
.asciinema-theme-tango .bg-6 {
  background-color: #06989A;
}
.asciinema-theme-tango .fg-7 {
  color: #D3D7CF;
}
.asciinema-theme-tango .bg-7 {
  background-color: #D3D7CF;
}
.asciinema-theme-tango .fg-8 {
  color: #555753;
}
.asciinema-theme-tango .bg-8 {
  background-color: #555753;
}
.asciinema-theme-tango .fg-9 {
  color: #EF2929;
}
.asciinema-theme-tango .bg-9 {
  background-color: #EF2929;
}
.asciinema-theme-tango .fg-10 {
  color: #8AE234;
}
.asciinema-theme-tango .bg-10 {
  background-color: #8AE234;
}
.asciinema-theme-tango .fg-11 {
  color: #FCE94F;
}
.asciinema-theme-tango .bg-11 {
  background-color: #FCE94F;
}
.asciinema-theme-tango .fg-12 {
  color: #729FCF;
}
.asciinema-theme-tango .bg-12 {
  background-color: #729FCF;
}
.asciinema-theme-tango .fg-13 {
  color: #AD7FA8;
}
.asciinema-theme-tango .bg-13 {
  background-color: #AD7FA8;
}
.asciinema-theme-tango .fg-14 {
  color: #34E2E2;
}
.asciinema-theme-tango .bg-14 {
  background-color: #34E2E2;
}
.asciinema-theme-tango .fg-15 {
  color: #EEEEEC;
}
.asciinema-theme-tango .bg-15 {
  background-color: #EEEEEC;
}
.asciinema-theme-tango .ap-overlay-loading .ap-loader {
  border-color: rgba(204, 204, 204, 0.3) rgba(204, 204, 204, 0.5) rgba(204, 204, 204, 0.7) #cccccc;
}
.asciinema-theme-tango .fg-8,
.asciinema-theme-tango .fg-9,
.asciinema-theme-tango .fg-10,
.asciinema-theme-tango .fg-11,
.asciinema-theme-tango .fg-12,
.asciinema-theme-tango .fg-13,
.asciinema-theme-tango .fg-14,
.asciinema-theme-tango .fg-15 {
  font-weight: bold;
}
