@import "./variables/variables";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/transitions";

//$slick-font-path: "~slick-carousel/slick/fonts/";
//$slick-loader-path: "~slick-carousel/slick/";
//
//@import '~slick-carousel/slick/slick', '~slick-carousel/slick/slick-theme';

@import './base/fonts';
@import './base/base';
@import './base/typography';

// Partials
@import "../../node_modules/prismjs/plugins/line-numbers/prism-line-numbers.css";
@import "./partials/prism";

@import "./partials/shared";
@import "./partials/home";
@import "./partials/post";
@import "./partials/workshop";
@import "./partials/about";
@import "./partials/workshop-pagination";
@import "asciinema-player.css"