/**********************************
*** Heading
**********************************/
.heading {
  margin-bottom: 2%
}

/**********************************
*** Wrap
**********************************/
.wrap {
  position: relative;
}

/**********************************
*** Header
**********************************/
.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: {
   top: 2.6875rem;
   bottom: 2.6875rem;
  }
  z-index: 3;
}

.header-logo {
  margin-top: 0.4rem;
  > a {
    display: block;
    max-width: 9.0625rem;
    overflow: hidden;
    > img {
      height: auto;
      max-width: 100%;
    }
  }
}

.header-mobile {
  position: fixed;
  right: 1rem;
  top: auto;

  z-index: 5;

  > button {
    &:focus {
      box-shadow: none;
    }
  }
}

.header-mobile__nav {
  position: fixed;
  right: 2rem;
  top: 5rem;
  z-index: 5;

  > ul {
    background-color: $primary;
    color: $white;
    padding: 1rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    > li {
      margin-bottom: 1rem;

      > a {
        display: block;
        color: $white;
        text-transform: uppercase;
        font-size: .875rem;
        letter-spacing: .8px;
        text-decoration: none;
        padding: 0.5rem 0;
        transition: color .2s ease-in-out;

        &.active {
          color: #272727;
        }

        &:hover:not(.active) {
          color: darken($gray-300, 50%);
        }
      }

      .description {
        font-size: 0.75rem;
        text-transform: none;
        color: lighten($white, 20%);
        padding-top: 0.25rem;
      }
    }
  }
}

.sign-in-button {
  margin-right: 50px;
  > a {
    display: flex;
    width: 130px;
    justify-content: center;
  }
}

.platform-nav-button-container:hover .platform__nav, 
.solution-nav-button-container:hover .platform__nav, 
.resource-nav-button-container:hover .platform__nav, 
.company-nav-button-container:hover .platform__nav, 
.platform__nav:hover {
  position: absolute;
  z-index: 5;
  // margin-top: 15px;
  width: 100%;
  left: 0%;
  justify-content: center;
  display: flex;
  flex-direction: row;  
  visibility: visible;
  opacity: 1;
  height: fit-content;
  transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;

  > ul {
    background-color: $primary;
    color: $white;
    padding: 2rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    opacity: 0.95;
    width: 100%;

    > li {
      width: 100%;
      display: flex;
      flex-direction: column;
      > a {
        width: 100%;
        display: block;
        color: $gray-100;
        text-transform: capitalize;
        font-size: .875rem;
        letter-spacing: .8px;
        text-decoration: none;
        padding: 0.5rem 0;
        transition: color .2s ease-in-out;
        margin-left: 0;

        &.active {
          color: $white;
        }

        &:hover:not(.active) {
          color: darken($black, 100%);
        }
      }

      .menu-description {
        font-size: 0.75rem;
        color: darken($gray-300, 70%);
        padding-top: 0.25rem;
        font-weight: normal;
      }
    }      
  }
}

.nav-button {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  padding: 10px;
  min-width: 150px; /* Ensure consistent width */

  svg {
    transition: transform 0.3s ease-in-out;
  }
}

.platform-nav-button-container, .solution-nav-button-container, .resource-nav-button-container, .company-nav-button-container {
  .nav-button {

    position: relative;
  }
}

.platform__nav {
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
  position: absolute; /* Ensure the dropdown is positioned absolutely */
  left: 0;
  right: 0;
  top: 100%; /* Position below the button */
  z-index: 5;
  width: 500px;
}

.platform-nav-button-container:hover .nav-button svg,
.solution-nav-button-container:hover .nav-button svg,
.resource-nav-button-container:hover .nav-button svg,
.company-nav-button-container:hover .nav-button svg,
.platform__nav:hover ~ .nav-button svg {
  transform: rotate(180deg);
}



.header-nav {
  position: relative;
  width: 60%;
  > nav {
    // padding-right: 1.5625rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a {
      color: #000000;
      text-decoration: none;
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: .8px;
      margin: {
        // left: 1.5625rem;
        // right: 1.5625rem;
      };

      &.active {
        color: $primary;
        text-decoration: none;
      }
      
      &:hover:not(.active) {
          text-decoration: none;
      }
    }
  }
}

/**********************************
*** Book
**********************************/

.book-hero-content {
  padding: {
    top: 9.5rem;
    bottom: 3.125rem;
  }
  position: relative;
  > .row {
    z-index: 2;
    position: relative;
  }
  @include media-breakpoint-up(md) {
    padding: {
      top: 9.5rem;
      bottom: 3.5rem;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: {
      top: 12.5rem;
      bottom: 3.5rem;
    }
  }
}

.book-section-split {
  border-bottom: 2px dotted rgba(#BBC2CE, .55);
}

.first-para {
  padding: 9;
  margin-top: 2%;
}

/**********************************
*** Hero
**********************************/
.hero-content {
  border-bottom: 0px dotted rgba(#BBC2CE, .55);
  padding: {
    top: 9.5rem;
    bottom: 3.125rem;
  }
  position: relative;
  > .row {
    z-index: 2;
    position: relative;
  }
  @include media-breakpoint-up(md) {
    padding: {
      top: 9.5rem;
      bottom: 9.5rem;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: {
      top: 12.5rem;
      bottom: 12.5rem;
    }
  }
}

.hero-content-live {
  padding: {
    top: 9.5rem;
    bottom: 0.5rem;
  }
  position: relative;
  > .row {
    z-index: 2;
    position: relative;
  }
  @include media-breakpoint-up(md) {
    padding: {
      top: 9.5rem;
      bottom: 1.5rem;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: {
      top: 12.5rem;
      bottom: 1.5rem;
    }
  }
}

.hero-deco__top {
  position: absolute;
  top: 10.625rem;
  left: -11.375rem;
  > img {
    height: auto;
    max-width: 100%;
  }
}


.hero-deco__bottom {
  position: absolute;
  bottom: 7.625rem;
  right: -5.375rem;
  > img {
    height: auto;
    max-width: 100%;
  }
}

.hero-article {
  padding-bottom: 3.2rem;
  @include media-breakpoint-up(md) {
    max-width: 29rem;
  }
}

.hero-image {
  > img {
    max-width: 100%;

  }
  @include media-breakpoint-up(xl) {
    position: relative;
    padding-bottom: 78.80%;
    width: 100%;
    > img {
      position: absolute;
      height: auto;
      top: 0;
      left: 0;
      max-width: 130%;
      width: 130%;
    }
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.hide {
  display: none;
}

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#video_overlay {
  position: relative;
  float:left;
  background-color:rgba(255,255,255,0.98);
  color:#000;
  text-align:center;
  z-index: 5;
  opacity: 1;
}

.responsive-aspect-ratio {
  display: block;
  width: 100%;
  max-width: 566px;
  max-height: 347px;
  height: auto !important;
  @include media-breakpoint-down(md) {
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }
  img {
    max-width: 100%;
    object-fit: contain;
    overflow: hidden;
  }
}


/**********************************
*** Features
**********************************/
.features-content {
  padding-top: 4.2rem;
  padding-bottom: 5.5rem;
  @include media-breakpoint-up(lg) {
    padding-top: 5.3125rem;
    padding-bottom: 7.5rem;
  }
}

.features-items {
  padding-top: 3.6875rem;
  @include media-breakpoint-up(md) {
    padding-top: 4.6875rem;
  }
}

.feature-article {
  padding-top: 2.8125rem;
  padding-bottom: 2.8125rem;
  @include media-breakpoint-up(md) {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  &:nth-child(even) {
    > div {
      &:first-child {
        @include media-breakpoint-up(md) {
          order: 2;
        }
      }
      &:last-child {
        @include media-breakpoint-up(md) {
          order: 1;
        }
      }
    }
  }
}

.feature-article__content {
  > div {
    max-width: 27.5rem;
    text-align: center;
    margin: {
      left: auto;
      right: auto;
    };
    @include media-breakpoint-up(md) {
      text-align: left;
      margin: {
        left: 0;
        right: 0;
      }
    }
    > p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.feature-article__image {
  > img {
    display: block;
    height: auto;
    max-width: 20.625rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @include media-breakpoint-up(md) {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }
  }
}

/**********************************
*** Discuss
**********************************/

.discuss-content__image {
  max-width: 100%;
}

.discuss-row-margin {
  margin-top: 15px;
}

.text-decoration-none {
  text-decoration: none !important;
}

.discuss {
  background-color: white;
  height: 100%;
  &.--private {
    background-color: #EEF8FC;
    .discuss-article {
      border-color: #55C1E2;
    }
  }
}

.discusss-content {
  padding-top: 5rem;
  padding-bottom: 3.5625rem;
  border-top: 2px dotted rgba(#BBC2CE, .55);
  &.--border-bottom {
    border-bottom: 2px dotted rgba(#BBC2CE, .55);
  }
  @include media-breakpoint-up(md) {
    padding-top: 5rem;
    padding-bottom: 6.5625rem;
  }
}

.discuss-snippets-header {
  padding-bottom: 4.375rem;
}

.discuss-article {
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  border: 1px solid #E4E8EC;
  border-radius: 0.375rem;
  height: 100%;
  padding: 1.875rem;
  transition: box-shadow .2s ease-in-out;
  &:hover {
    box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
  }
}

.discuss-header {
  flex: 0 0 5.9375rem;
  max-height: 5.9375rem;
  position: relative;
}

.discuss-items {
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up(sm) {
    max-width: 36rem;
  }
  @include media-breakpoint-up(md) {
    max-width: none;
    margin: {
      left: 0;
      right: 0;
    };
  }
  >.row {
    > div {
      margin-bottom: 1.5625rem;
    }
  }
}

.discuss-content {
  flex: 1 1 auto;
  position: relative;
  padding-top: 1.875rem;
}

.discuss-content__text {
  > h3 {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.444;
    > a {
      text-decoration: none;
    }
  }
  > p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.57;
    white-space: pre-wrap;
  }
}

.discuss-content__details {
  p {
    font-size: 0.875rem;
    font-weight: 400;
    strong {
      color: #000000;
      font-weight: 500;
    }
  }
}

.discuss-content__date {
  position: absolute;
  top: 0;
  left: 0;
  > a,
  > span {
    color: $primary;
    letter-spacing: .8px;
    font-weight: 600;
    font-size: 0.75rem;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.discuss-footer {
  flex: 0 0 5.0625rem;
  max-height: 5.0625rem;
}

.discuss-pagination {
  padding-top: 1.7rem;
  @include media-breakpoint-up(md) {
    padding-top: 3.125rem;
  }
  .paginationjs-page,
  .paginationjs-prev,
  .paginationjs-next {
    margin: {
      left: 0.3125rem;
      right: 0.3125rem;
    }
    > a {
      font-family: Arial, sans-serif;
      padding: 0.75rem 1rem 0.65rem;
    }
    &:first-child {
      > a {
        border-radius: 0;
        padding-left: 1.1rem;
        padding-right: 1.1rem;
      }
    }
    &:last-child {
      > a {
        border-radius: 0;
        padding-left: 1.1rem;
        padding-right: 1.1rem;
      }
    }
    a {
      font-size: .875rem;
    }
  }
}

/**********************************
*** Blogs
**********************************/

.blog-content {
  flex: 1 1 auto;
  position: relative;
  padding-top: 1.875rem;
  // margin-left: 5rem;
  // margin-right: 5rem;
  margin-left: 0;
  margin-right: 0;

  // @include media-breakpoint-down(lg) {
  //   margin-left: 0;
  //   margin-right: 0;
  // }

  // Styling for the title
  .blog-title {
    margin-top: 4rem;
    margin-bottom: 1rem;
    text-align: center;
  
    > h1 {
      font-weight: 700; // Adjust as needed
      color: #424242; // Black color
      margin-bottom: 1.5rem; // Space below the title
    }  
  
    .blog-meta {
      display: flex;
      flex-direction: column;
      margin-right: 1rem; // Adjust as needed
  
      .last-edited {
        font-size: 0.875rem; // Adjust as needed
        color: #8A8A8A; // Gray color
        margin-bottom: 0.1rem; // Space below the date
      }
  
      .reading-time {
        font-size: 1.375rem; // Adjust as needed
        color: #424242;
  
        .reading-time-number {
          color: #55c1e2; // Blue color
          font-weight: 400; // Light weight
        }
      }
    }
  }

  .cooked {
    // padding-left: 14.0625rem;
    // padding-right: 14.0625rem;
    width: 720px;
    display: block;
    margin: auto;

  }
  
  @media (max-width: 770px) {
    .cooked {
      width: 100%;
    }
  }
  
  // @media (max-width: 768px) {
  //   .cooked {
  //     padding-left: 5rem;
  //     padding-right: 5rem;
  //   }
  // }
  
  // @media (max-width: 480px) {
  //   .cooked {
  //     padding-left: 2rem;
  //     padding-right: 2rem;
  //   }
  // }   

  // General styling for paragraphs
  p {
    font-weight: 400;
    line-height: 1.57;
    color: #8A8A8A;
    white-space: pre-wrap;

    strong {
      color: #424242; // Black color
      font-weight: 500;
    }
  }

  // Additional styling for headings within the content

  h1 {
    color: #424242; // Black color
    margin-top: 1.5rem; // Space above headings
    margin-bottom: 1rem; // Space below headings
    font-size: 2.5rem;
  }

  h2, h3, h4, h5, h6 {
    color: #424242; // Black color
    margin-top: 1.5rem; // Space above headings
    margin-bottom: 1rem; // Space below headings
  }

  // Styling for links within the content
  a {
    color: #55c1e2; // Link color, adjust as needed
    font-weight: 300;
    text-decoration: none;
    &:hover {
      text-decoration: underline; // Underline on hover
    }
  }

  // Styling for lists within the content
  ul, ol {
    margin-left: 1.5rem; // Indent lists
    margin-bottom: 1.5rem; // Space below lists
    li {
      margin-bottom: 0.5rem; // Space between list items
      font-weight: 300;
      line-height: 1.37;
      color: #8A8A8A; // Black color
      white-space: pre-wrap;
    }
  }

  pre {
    background: #f5f5f5; // Light grey background
    border: 1px solid #ddd; // Light border
    border-radius: 4px; // Rounded corners
    padding: 1rem; // Padding inside the pre element
    overflow-x: auto; // Horizontal scroll for long code lines
    margin-bottom: 1.5rem; // Space below pre elements

    code {
      display: block;
      color: #333; // Dark text color
      font-size: 0.9rem; // Adjust font size as needed
    }
  }

  code {
    background: #f5f5f5; // Light grey background for inline code
    padding: 0.2rem 0.4rem; // Padding inside inline code elements
    border-radius: 4px; // Rounded corners
    color: #c7254e; // Custom text color for inline code
    font-size: 0.9rem; // Adjust font size as needed
  }

  blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    unicode-bidi: isolate;
    margin-left: 0;
    margin-right: 0;
    padding: 12px;
    border-left: 5px solid #8A8A8A;
    background-color: rgb(249, 249, 249);
    clear: both;
  }

  .youtube-onebox {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    margin-bottom: 1rem;
  }

  .youtube-onebox iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
  }

}


.discuss-blog-pagination {
  padding-top: 1.7rem;
  @include media-breakpoint-up(md) {
    padding-top: 3.125rem;
  }
  .paginationjs-page,
  .paginationjs-prev,
  .paginationjs-next {
    margin: {
      left: 0.3125rem;
      right: 0.3125rem;
    }
    > a {
      font-family: Arial, sans-serif;
      padding: 0.75rem 1rem 0.65rem;
    }
    &:first-child {
      > a {
        border-radius: 0;
        padding-left: 1.1rem;
        padding-right: 1.1rem;
      }
    }
    &:last-child {
      > a {
        border-radius: 0;
        padding-left: 1.1rem;
        padding-right: 1.1rem;
      }
    }
    a {
      font-size: .875rem;
    }
  }
}

/**********************************
*** Workshops
**********************************/
.workshop {
  background-color: white;
  height: 100%;
  &.--private {
    background-color: #EEF8FC;
    .workshop-article {
      border-color: #55C1E2;
    }
  }
}

.workshops-content {
  padding-top: 5rem;
  padding-bottom: 3.5625rem;
  border-top: 2px dotted rgba(#BBC2CE, .55);
  &.--border-bottom {
    border-bottom: 2px dotted rgba(#BBC2CE, .55);
  }
  @include media-breakpoint-up(md) {
    padding-top: 5rem;
    padding-bottom: 6.5625rem;
  }
}

.workshops-header {
  padding-bottom: 4.375rem;
}

.workshop-article {
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  border: 1px solid #E4E8EC;
  border-radius: 0.375rem;
  height: 100%;
  padding: 1.875rem;
  transition: box-shadow .2s ease-in-out;
  &:hover {
    box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
  }
}

.workshop-header {
  flex: 0 0 5.9375rem;
  max-height: 5.9375rem;
  position: relative;
}

.workshop-icon {
  > div {
    > img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
  &.--one {
    > div {
      max-width: 4.375rem;
    }
  }
  &.--two {
    > div {
      max-width: 4.125rem;
      margin-right: 0.375rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.--three {
    > div {
      max-width: 3.75rem;
      margin-right: 0.25rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.workshop-pricetag {
  background: #f7f7f7;
  border: 1px dotted #C7D1DB;
  border-radius: 0 5px 5px 0;
  color: #9DA8B5;
  font-weight: 600;
  display: inline-block;
  font-size: 0.75rem;
  line-height: 2.23rem;
  height: 2.125rem;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.875rem 0 1.05rem;
  text-transform: uppercase;
  margin: 0 0 0 10px;
  white-space: nowrap;
  z-index: 2;
  &:before {
    position: absolute;
    background: #f7f7f7;
    content: "\25CF";
    color: $white;
    text-shadow: 0 0 1px #aaa;
    font-size: 14px;
    line-height: 24px;
    text-indent: 6px;
    top: 0.25rem;
    left: -0.8125rem;
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(45deg);
    border-left: 1px dotted #C7D1DB;
    border-bottom-left-radius: 6px;
    border-bottom: 1px dotted #C7D1DB;
  }
  &.--discount {
    background-color: #FEEAD3;
    border-color: #DCA795;
    color: #F85822;
    &:before {
      background-color: #FEEAD3;
      border-left: 1px dotted #DCA795;
      border-bottom-left-radius: 6px;
      border-bottom: 1px dotted #DCA795;
      text-shadow: 0 0 1px #DCA795;
    }
  }
}

.workshops-items {
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up(sm) {
    max-width: 36rem;
  }
  @include media-breakpoint-up(md) {
    max-width: none;
    margin: {
      left: 0;
      right: 0;
    };
  }
  >.row {
    > div {
      margin-bottom: 1.5625rem;
    }
  }
}

.workshop-content {
  flex: 1 1 auto;
  position: relative;
  padding-top: 1.875rem;
}

.workshop-content__text {
  > h3 {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.444;
    > a {
      text-decoration: none;
    }
  }
  > p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.57;
    white-space: pre-wrap;
  }
}

.workshop-content__details {
  p {
    font-size: 0.875rem;
    font-weight: 400;
    strong {
      color: #424242;
      font-weight: 500;
    }
  }
}

.workshop-content__date {
  position: absolute;
  top: 0;
  left: 0;
  > a,
  > span {
    color: $primary;
    letter-spacing: .8px;
    font-weight: 600;
    font-size: 0.75rem;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.workshop-footer {
  flex: 0 0 5.0625rem;
  max-height: 5.0625rem;
}

.workshop-pagination {
  padding-top: 1.7rem;
  @include media-breakpoint-up(md) {
    padding-top: 3.125rem;
  }
  .paginationjs-page,
  .paginationjs-prev,
  .paginationjs-next {
    margin: {
      left: 0.3125rem;
      right: 0.3125rem;
    }
    > a {
      font-family: Arial, sans-serif;
      padding: 0.75rem 1rem 0.65rem;
    }
    &:first-child {
      > a {
        border-radius: 0;
        padding-left: 1.1rem;
        padding-right: 1.1rem;
      }
    }
    &:last-child {
      > a {
        border-radius: 0;
        padding-left: 1.1rem;
        padding-right: 1.1rem;
      }
    }
    a {
      font-size: .875rem;
    }
  }
}

/**********************************
*** Snippet
**********************************/
.snippet-content {
  padding: {
    top: 6.25rem;
    bottom: 6.25rem;
  }
}

.snippet-header {
  padding-bottom: 4.0625rem;
}

.snippet-code {
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  max-width: 53.75rem;
  margin: {
    left: auto;
    right: auto;
  }
}

.snippet-code__header {
  background-color: #E5EAEE;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  flex: 0 0 3.125rem;
  max-height: 3.125rem;
  > div {
    margin: {
      top: auto;
      bottom: auto;
      right: auto;
    }
    padding-left: 1.875rem;
    > span {
      background-color: $white;
      border-radius: 50%;
      display: inline-block;
      height: .5rem;
      width: .5rem;
    }
  }
}

.snippet-code__body {
  background-color: $white;
  flex: 0 0 26.125rem;
  max-height: 26.125rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  overflow: hidden;
  position: relative;
}

.snippet-code__editor {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
}

.snippet-code__oval {
  position: absolute;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 2;
  > a {
    > img {
      height: auto;
      max-width: 3.125rem;
    }
  }
}

.snippet-code__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  z-index: 1;
}

/**********************************
*** Action
**********************************/
.action-content {
  padding-bottom: 5rem;
}

.action-wrap {
  border: 1px solid #E4E8EC;
  border-radius: .25rem;
  background-image: url("../../assets/images/content/action-deco1.png"), url("../../assets/images/content/action-deco2.png");
  background-position: left 20px bottom, right 0 bottom;
  background-repeat: no-repeat, no-repeat;
  position: relative;
}

.action-deco__top,
.action-deco__bottom {
  position: absolute;
  > img {
    height: auto;
    max-width: 50%;
  }
}

.action-deco__top {
  top: -0.8125rem;
  left: 2rem;
  &.--fix {
    top: -0.875rem;
  }
}

.action-deco__bottom {
  bottom: -0.75rem;
  right: 1rem;
  &.--fix {
    bottom: -0.6875rem;
  }
}

.action-article {
  max-width: 40.625rem;
  padding: {
    top: 3.75rem;
    right: 2.5rem;
    bottom: 2.875rem;
    left: 2.5rem;
  }
  margin: {
    left: auto;
    right: auto;
  }
}


.hidden {
  display: none !important;
}

/**********************************
*** Footer
**********************************/
.footer-content {
  border-top: 1px solid #E1E1E1;
  padding-top: 3.1875rem;
  padding-bottom: 2.4375rem;
  position: relative;
  @include media-breakpoint-up(md) {
    padding-bottom: 5.4375rem;
  }
}

.footer-deco {
  position: absolute;
  top: -0.875rem;
  left: 6.25rem;
  &.--fix {

  }
  > img {
    height: auto;
    max-width: 50%;
  }
  @include media-breakpoint-up(md) {
    top: -0.8125rem;
    &.--fix {
      top: -0.875rem;
    }
  }
}

.footer-about {
  > a {
    display: inline-block;
    padding-bottom: .675rem;
    > img {
      display: block;
      height: auto;
      max-width: 100%;
    }
  }
  > p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.857;
    max-width: 18.75rem;
    margin: {
      left: auto;
      right: auto;
    }
    @include media-breakpoint-up(lg) {
      margin: {
        left: 0;
        right: 0;
      };
    }
  }
}

.footer-links,
.footer-info,
.footer-contact {
  > p {
    color: #000000;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 2.4rem;
  }
  > ul {
    > li {
      > a {
        color: #919191;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 3;
        letter-spacing: .8px;
        text-transform: uppercase;
        text-decoration: none;
      }
      &.active {
        > a {
          color: #000000;
        }
      }
    }
  }
}

.footer-address {
  padding-bottom: 2.0625rem;
  > p {
    color: #919191;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.857;
    margin: 0;
  }
}

.footer-social {

  padding-bottom: 1.25rem;

  > ul {
    > li {
      margin-right: 1.25rem;
      &:last-child {
        margin-right: 0;
      }
      > a {
        .footer-social__circle {
          transition: fill .2s ease-in-out;
        }
        &:hover {
          .footer-social__circle {
            fill: $primary;
          }
        }
      }
    }
  }
}

.cursorIcon {
  cursor: pointer;
}

.footer-copyright {
  @include media-breakpoint-down(sm) {
    padding-top: 3.625rem;
    order: 12;
  }
  > p {
    color: #ABABAB;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 3;
  }
  a {
    color: #919191;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.857;
    text-decoration: none;
  }
}

.footer-end-links {
  > p {
    color: #ABABAB;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.857;
  }
  a {
    color: #919191;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 3;
    letter-spacing: .8px;
    text-decoration: none;
  }
}

/**********************************
*** Workshop Detail Page
**********************************/

.workshop-page-module {
  margin: 8%;
}


/**********************************
*** Community

.community-content {
  border-bottom: 2px dotted rgba(#BBC2CE, .55);
 }

**********************************/

/**********************************
*** Notification Bar
**********************************/

.notification-enabled {
  padding: {
   top: 0rem;
   bottom: 2.6875rem;
  }
  .notification-top-bar {
    display: flex;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
    height: 31px;
    line-height: 31px;
    width: 100%;
    background: #55C1E2;
    text-align: center;
    color: #FFFFFF;
    font-weight: lighter;
    font-size: 1.125rem;
  
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      min-height: 31px;
      height: fit-content;
      padding: 1%;
    }
  }
  
  .notification-top-bar p {
    padding: 0;
    margin: 0;
    color: #FFFFFF;
    font-size: 1.125rem;
    @include media-breakpoint-down(xs) {
      font-size: 1rem;
    }
  }
  .notification-top-bar p a {
    color: #FFFFFF;
    font-weight: normal;
    text-decoration: none;
    font-size: 1.125rem;
    @include media-breakpoint-down(xs) {
      font-size: 1rem;
    }
  }
  .notification-top-bar p a:hover {
    color: #424242;
    text-decoration: none;
    font-size: 1.125rem;
    @include media-breakpoint-down(xs) {
      font-size: 1rem;
    }
  }
}

.notification-disabled {
  
  .notification-top-bar {
    display: none;
  }
}