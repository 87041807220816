// Color override
$theme-colors: (
"primary": #55C1E2
);

$primary: #55C1E2;
$black: #424242;
$text-color: #8A8A8A;

// Buttons
$btn-font-weight: 600;
$btn-font-family: 'Averta', sans-serif;
$btn-font-size: 0.75rem;
$btn-border-radius: 0.25rem;

// Pagination
$pagination-padding-x: .875rem;
$pagination-color: #8A8A8A;
$pagination-hover-bg: transparent;
$pagination-border-color: rgba(#E4E8EC, .5);
$pagination-active-bg: transparent;
$pagination-active-color: $primary;
$pagination-active-border-color: #E4E8EC;
$pagination-disabled-color: rgba(#8A8A8A, .5);
$pagination-disabled-border-color: rgba(#E4E8EC, .5);

// Popover
$popover-max-width: 508px;
$popover-border-color: #E4E8EC;
$popover-body-padding-y: 1.875rem;
$popover-body-padding-x: 1.875rem;
$popover-body-color: #8A8A8A;

// Forms
$input-border-color: #E4E8EC;
