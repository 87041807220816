.hero {
  h1 {
    color: $black;
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(md) {
      font-size: 2.8rem;
      margin-bottom: 0;
    }
    @include media-breakpoint-up(xl) {
      font-size: 3.5rem;
    }
  }
}

h1 {
  color: $black;
  font-size: 2.0rem;
  @include media-breakpoint-up(md) {
    font-size: 2.8rem;
  }
}

h2 {
  color: $black;
  font-size: 1.7rem;
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

h3 {
  color: $black;
  font-size: 1.25rem;
  line-height: 1.5;
  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
    line-height: 1.77777;
  }
}

p {
  color: $text-color;
  font-size: 1rem;
  line-height: 1.5;
  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
    line-height: 1.77777;
  }
}
