/**********************************
*** About Us
**********************************/
.hero-about {
  padding-top: 5rem;
}

/**********************************
*** SecDim
**********************************/
.secdim-content {
  padding-bottom: 3.25rem;
  @include media-breakpoint-up(md) {
    padding-bottom: 6.25rem;
  }
}

.secdim-article {
  max-width: 40.625rem;
  margin: {
    left: auto;
    right: auto;
  };
  padding-top: 3.125rem;
}

/**********************************
*** Our Team
**********************************/
.team-header {
  padding-bottom: 4.375rem;
}

.team-content {
  border-top: 2px dotted rgba(#BBC2CE, .55);
  border-bottom: 2px dotted rgba(#BBC2CE, .55);
  padding: {
    top: 6.25rem;
    bottom: 4.375rem;
  }
}

.team-members {
  padding-top: 9.375rem;
  > div {
    > div {
      margin-bottom: 13rem;
      &:last-child {
        margin-bottom: 0;
      }
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.team-member {
  border-radius: .25rem;
  border: 1px solid #E4E8EC;
  background-color: $white;
  height: 100%;
  padding: 8.125rem 1.5625rem 1.5625rem 1.5625rem;
  position: relative;
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
}

.team-member__image {
  position: absolute;
  top: -9.375rem;
  left: 0;
  right: 0;
  > img {
    border-radius: .25rem;
    display: block;
    margin: {
      left: auto;
      right: auto;
    };
    height: auto;
    max-width: 100%;
  }
}

.team-member__position {
  color: $primary;
  font-weight: 400;
}

.team-member__content {
  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
}

/**********************************
*** Contact
**********************************/
.contact-content {
  padding: {
    top: 4.375rem;
    bottom: 5rem;
  }
}

.contact-touch__header {
  padding-bottom: 4.6875rem;
}

.contact-touch__articles {
  padding-bottom: 2.5rem;
  > .row {
    > div {
      &:first-child {
        .contact-article {
          background-image: url("../../assets/images/about/contact-article1-bg.png");
        }
      }
      &:last-child {
        .contact-article {
          background-image: url("../../assets/images/about/contact-article2-bg.png");
        }
      }
    }
  }
}

.contact-article {
  background-repeat: no-repeat;
  background-position: right bottom;
  border: 1px solid #E4E8EC;
  border-radius: .25rem;
  height: 100%;
  padding: 3.75rem 5rem 3.75rem 3.125rem;
}

.contact-deco__top,
.contact-deco__bottom,
.contact-deco__left {
  position: absolute;
  > img {
    max-width: 50%;
    height: auto;
    margin: 0;
  }
}

.contact-deco__top {
  top: -0.8125rem;
  left: 6.25rem;
  @include media-breakpoint-up(md) {
    top: -0.8125rem;
  }
}

.contact-deco__bottom {
  bottom: -0.625rem;
  right: 0;
  @include media-breakpoint-up(md) {
    bottom: -0.625rem;
  }
}

.contact-deco__left {
  left: 0.875rem;
  bottom: 3rem;
  @include media-breakpoint-up(md) {
    left: 0.875rem;
  }
}

.contact-questions {
  padding-top: 3.25rem;
  @include media-breakpoint-up(md) {
    padding-top: 6.25rem;
  }
}

.contact-questions__header {
  padding-bottom: 3.75rem;
}

.contact-form {
  border: 1px solid #E4E8EC;
  border-radius: .25rem;
  max-width: 45rem;
  margin: {
    left: auto;
    right: auto;
  }
  padding: 1.15rem 1.75rem;
  position: relative;
  label {
    color: #8A8A8A;
  }
  textarea {
    resize: none;
  }
  textarea::placeholder {
    opacity: .5;
    color: gray;
  }
  @include media-breakpoint-up(md) {
    padding: 1.875rem 3.125rem;
  }
}

.form-deco__top,
.form-deco__left {
  position: absolute;
  > img {
    max-width: 50%;
    height: auto;
    margin: 0;
  }
}

.form-deco__top {
  top: -0.875rem;
  left: 6.25rem;
  @include media-breakpoint-up(md) {
    top: -0.875rem;
  }
}

.form-deco__left {
  left: -2px;
  bottom: 3rem;
  @include media-breakpoint-up(md) {
    left: -2px;
  }
}
