/**
 * GHColors theme by Avi Aryan (http://aviaryan.in)
 * Inspired by Github syntax coloring
 */

code[class*="language-"],
    pre[class*="language-"] {
    color: #393A34;
    font-family: "Roboto Mono", Courier, monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    font-size: .9em;
    line-height: 2em;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

pre > code[class*="language-"] {
    font-size: .75rem;
    @include media-breakpoint-up(md) {
        font-size: 1em;
    }
}

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
    background: #b3d4fc;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
    code[class*="language-"]::selection, code[class*="language-"] ::selection {
    background: #b3d4fc;
}

/* Code blocks */
pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
    background-color: white;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
    padding: .2em;
    padding-top: 1px;
    padding-bottom: 1px;
    background: #f8f8f8;
    border: 1px solid #dddddd;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: #999988;
    font-style: italic;
}

.token.namespace {
    opacity: .7;
}

.token.string,
.token.attr-value {
    color: #e3116c;
}

.token.punctuation,
.token.operator {
    color: #393A34; /* no highlight */
}

.token.entity,
.token.url,
.token.symbol,
.token.number,
.token.boolean,
.token.variable,
.token.constant,
.token.property,
.token.regex,
.token.inserted {
    color: #36acaa;
}

.token.atrule,
.token.keyword,
.token.attr-name,
.language-autohotkey .token.selector {
    color: #000000;
    font-weight: bold;
}

.token.function,
.token.deleted,
.language-autohotkey .token.tag {
    color: #28B797;
}

.token.tag,
.token.selector,
.language-autohotkey .token.keyword {
    color: #00009f;
}

.token.class-name {
    color: #55C1E2;
    font-weight: bold;
}

.token.important,
.token.function,
.token.bold {
    font-weight: 400;
}

.token.italic {
    font-style: italic;
}

/* Overrides line-number */
.line-numbers {
    .line-numbers-rows {
        border-right: medium none;
        > span:before {
            color: #D4D4D4;
        }
    }
}
