html {
  font-size: 100%;
  overflow-x: hidden;
  max-width: 100%;
}

body {
  background-color: $white;
  font-size: 1rem;
  font-family: 'Averta', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  max-width: 100%;
}

strong {
  font-weight: 500;
  color: #000000;
}

.container {
  max-width: 75rem;
  padding: 0 0.9375rem;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: .875px;
  @include media-breakpoint-down(xs) {
    font-size: 0.625rem;
  }
}

.btn-primary {
  padding: 1rem 1rem .875rem 1rem;
  color: $white;
  @include media-breakpoint-up(sm) {
    padding: 1.25rem 2.1875rem 1rem 2.1875rem;
  }
}

.btn-outline-primary {
  padding: 1rem 1rem .875rem 1rem;
  &:hover,
  &:active {
    color: $white;
  }
  @include media-breakpoint-up(sm) {
    padding: 1.25rem 1.25rem 1rem 1.25rem;
  }
}

.popover {
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
  max-width: 17.5rem;
  @include media-breakpoint-up(sm) {
    max-width: 21.75rem;
  }
  @include media-breakpoint-up(md) {
    max-width: 21.75rem;
  }
  @include media-breakpoint-up(lg) {
    max-width: 31.75rem;
  }
}

.workshop-popover {
  > h4 {
    color: #000000;
    font-weight: 500;
    font-size: 0.875rem;
  }
  > p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5714;
    white-space: pre-wrap;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.workshop-pagination {

}

.discuss-pagination {

}
