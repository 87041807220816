/**********************************
*** Blog Post Styling

lg ≥ 992px
md ≥ 768px
sm ≥ 576px
xs < 576px

sm - md

**********************************/
.appsec-action-buttons {
  display: flex;
  flex-direction: row;

  @include media-breakpoint-down(xs) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 15px;

    > a:first-child {
      margin-top: 0;
      justify-content: center;
      align-items: center;
      margin-right: 0 !important;
    }
  
    > a {
      width: 175px;
      margin-top: 15px;
      justify-content: center;
      align-items: center;
      margin-right: 0 !important;
    }
  }

  @media (max-width: 1006px) and (min-width: 768px) {
    flex-direction: column;
    padding-bottom: 15px;

    > a:first-child {
      margin-top: 0;
    }
  
    > a {
      width: 175px;
      margin-top: 15px;
    }
  }
}

.post-community {
  @include media-breakpoint-down(xs) {
    text-align: center;
  }
}


.author-section {
  margin-top: 4rem;

  .author-article {
    display: flex;

    @include media-breakpoint-down(xs) {
      flex-direction: column;

      padding: {
        top: 2rem;
        right: 1rem;
        bottom: 2rem;
        left: 1rem;
      }

      .author-details {
        margin-top: 1rem;
        text-align: center;

        h3 {
          margin: 0;
          font-size: 1rem;
          font-weight: 700;
        }

        p {
          margin: 0;
          font-size: 0.75rem;
          color: #8A8A8A;
        }
      }
    }

    align-items: center;
    padding: {
      top: 3.75rem;
      right: 2.5rem;
      bottom: 2.875rem;
      left: 2.5rem;
    }
    margin: {
      left: auto;
      right: auto;
    }

    .author-photo {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    .author-details {
      margin-left: 3rem;

      h3 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 700;
      }

      p {
        margin: 0;
        font-size: 1rem;
        color: #8A8A8A;
      }
    }
  }
}
