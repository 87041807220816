/**********************************
*** Snippets
**********************************/
.snippets-content {
  padding-bottom: 4.5625rem;
  @include media-breakpoint-up(md) {
    padding-bottom: 6.5625rem;

  }
}

.snippets-header {
  padding-bottom: 2.0625rem;
  @include media-breakpoint-up(md) {
    padding-bottom: 4.0625rem;
  }
}

.snippets-code {
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.03);
}

.snippets-code__header {
  background-color: #E5EAEE;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  flex: 0 0 3.125rem;
  max-height: 3.125rem;
  > div {
    margin: {
      top: auto;
      bottom: auto;
      right: auto;
    }
    padding-left: 1.875rem;
    > span {
      background-color: $white;
      border-radius: 50%;
      display: inline-block;
      height: .5rem;
      width: .5rem;
    }
  }
}

.snippets-code__body {
  flex: 0 0 25.9375rem;
  max-height: 25.9375rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  overflow: hidden;
}

.snippets-code__sidebar {
  background-color: #F2F5F7;
  padding: .5rem 1rem;
  @include media-breakpoint-up(sm) {
    flex: 0 0 11rem;
    max-width: 11rem;
    padding: 1rem;
  }
  @include media-breakpoint-up(md) {
    flex: 0 0 15rem;
    max-width: 15rem;
    padding: 1.875rem;
  }
}

.snippets-code__editor {
  flex: 1 1 auto;
  position: relative;
}

.snippets-code__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  z-index: 1;
}

.snippets-code__oval {
  position: absolute;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 2;
  > a {
    > img {
      height: auto;
      max-width: 3.125rem;
    }
  }
}

.snippets-code__languages {
  > ul {
    list-style: none;
    margin: 0;
    padding: 0 0 .5rem 0;
    @include media-breakpoint-up(md) {
      padding: 0 0 1rem 0;
    }
    > li {
      &.active {
        > a {
          color: #1B4670;
        }
      }
      > a {
        color: #A9ADB1;
        font-size: .875rem;
        line-height: 2;
        font-weight: 500;
        text-decoration: none;
        @include media-breakpoint-up(md) {
          font-size: 1rem;
        }
      }
    }
  }
}

.snippets-code__text {
  border-top: 1px solid #E4E8EC;
  padding-top: .5rem;
  @include media-breakpoint-up(md) {
    padding-top: 1rem;
  }
  > p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.4667;
    margin-bottom: .5rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 1rem;
    }
  }
  > a {
    font-size: 0.75rem;
    text-decoration: none;
  }
}

/**********************************
*** Community
**********************************/
.community-header {
  padding-bottom: 4.6875rem;
}

.community-articles {
  padding-bottom: 1.25rem;
  > .row {
    > div {
      &:first-child {
        margin-bottom: 1.875rem;
        .community-article {
          background-image: url("../../assets/images/community/community-article1-bg.png");
        }
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
      &:last-child {
        .community-article {
          background-image: url("../../assets/images/community/community-article2-bg.png");
          background-size: 50%;
          @include media-breakpoint-up(md) {
            background-size: auto;
          }
        }
      }
    }
  }
}

.community-article {
  background-repeat: no-repeat;
  background-position: right bottom;
  border: 1px solid #E4E8EC;
  border-radius: .25rem;
  height: 100%;
  padding: 1.875rem;
  position: relative;
  @include media-breakpoint-up(sm) {
    padding: 3.75rem 5rem 3.75rem 3.125rem;
  }
}

.community-deco__top,
.community-deco__bottom,
.community-deco__left {
  > img {
    max-width: 50%;
    height: auto;
    margin: 0;
  }
}

.community-deco__top {
  position: absolute;
  top: -0.875rem;
  left: 6.25rem;
  @include media-breakpoint-up(md) {
    top: -0.8125rem;
  }
}

.community-deco__bottom {
  position: absolute;
  bottom: -0.6875rem;
  right: 0;
  @include media-breakpoint-up(md) {
    bottom: -0.75rem;
  }
}

.community-deco__left {
  position: absolute;
  left: -0.125rem;
  bottom: 3rem;
  @include media-breakpoint-up(md) {
    left: -0.125rem;
  }
}

.community-content {
  overflow: hidden;
  margin-bottom: 2.0rem;

  .community-title {
    font-size: 1.125rem;
    line-height: 1.77777;
    margin-bottom: 5.0rem;
    font-weight: 400;
    text-align: center !important;
  }
}
 
.community-partners {
  overflow: hidden;
  width: 100%; /* Adjust to fit parent container */

  .marquee-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap; /* Default: single row, no wrapping */
    gap: 2rem; /* Control spacing between logos */

    li {
      display: inline-flex; /* Use inline-flex for internal alignment */
      align-items: center; /* Align images vertically */
      justify-content: center; /* Align images horizontally */
      list-style: none; /* Remove default list styling */

      img, svg, a img, a svg {
        max-width: 150px; /* Fixed max width for all logos */
        width: 100%; /* Scale logos responsively */
        height: auto; /* Maintain aspect ratio */
        object-fit: contain; /* Ensures the image is fully visible */
        filter: grayscale(100%); /* Apply full grayscale to images */
        opacity: 0.5;
        transition: filter 0.3s ease, opacity 0.3s ease; /* Smooth transition for hover effects */
      }

      img:hover, a img:hover, svg:hover, a svg:hover {
        filter: grayscale(0%); /* Restore colors on hover */
        opacity: 1;
      }
    }
  }
}

/* Force two rows at 950px */
@media (max-width: 950px) {
  .community-partners .marquee-content {
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: center; /* Center items */
    height: calc(150px * 2 + 2rem); /* Force height for two rows (logo height * 2 + spacing) */
    overflow: hidden; /* Hide any overflow for forced rows */
  }

  .marquee-content li {
    flex: 1 1 calc(33.33% - 2rem); /* Distribute logos evenly: 3 per row */
    max-width: calc(33.33% - 2rem); /* Prevent items from growing too large */
  }
}

/* Revert to natural wrapping at 768px */
@media (max-width: 768px) {
  .community-partners .marquee-content {
    flex-wrap: wrap; /* Allow natural wrapping */
    justify-content: center; /* Center items */
    height: auto; /* Remove forced height */
    overflow: visible; /* Revert to visible overflow */
  }

  .marquee-content li {
    flex: 1 1 auto; /* Allow items to resize naturally */
    max-width: 150px; /* Revert to default logo size */
  }
}


.collab-logos {
  display: flex;
  justify-content: space-between;

  > #sectalks_logo {
    margin-top: 2rem;

  }

  ul {
    display: table;
    width: 100%;
    list-style: none;
    position: relative;
    }
  ul li {
    display: table-cell;
    text-align: center;
    justify-content: center;
    }
  ul li a {
    display: block;
    text-decoration: none;
    }

  ul li a img {
    filter: grayscale(100%);
  }

  ul li a img:hover {
    filter: grayscale(0%);
  }

  @media (max-width: 730px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: auto;
    padding-bottom: auto;

    > #defcon_logo {
      margin-bottom: 2rem;
    }

    > #firstcon_logo {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }

    ul li {
      display: block;
      text-align: center;
      margin-bottom: 20px;
      }

  }

  .defcon__description {
    color: #55C1E2;
    position: relative;
    bottom: -20px; /* Adjust this value as per your design */
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    font-size: small;
    width: 75%;
    transition: opacity .4s, visibility .4s;
  }

  .defcon__wrap:hover .defcon__description {
    visibility: visible;
    z-index: 1;
    opacity: 1;
  }

  .firstcon__description {
    color: #55C1E2;
    position: relative;
    bottom: -20px; /* Adjust this value as per your design */
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    font-size: small;
    width: 75%;

    /* transition effect. not necessary */
    transition: opacity .4s, visibility .4s;
  }

  .firstcon__wrap:hover .firstcon__description {
    visibility: visible;
    z-index: 1;
    opacity: 1;
  }

  .sectalks__description {
    color: #55C1E2;
    position: relative;
    bottom: -20px; /* Adjust this value as per your design */
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    font-size: small;
    width: 75%;
    margin-top: 35px;

    /* transition effect. not necessary */
    transition: opacity .4s, visibility .4s;
  }

  .sectalks__wrap:hover .sectalks__description {
    visibility: visible;
    z-index: 1;
    opacity: 1;
  }

}

.btn {
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 4px;
}

/* --- Video Wrapper --- */
/* Adjust the spacing and sizing for the video side */
.video-wrapper {
  max-width: 850px;
  margin: 3rem auto;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 15px 40px rgba(0,0,0,0.1);
  background-color: #ffffff; /* pure white background for a clean, modern feel */
}

.responsive-video {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 7px;
  margin-top: 3%;
}

.fullwidth-bg {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 100vw;
 
}

features-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 0.75rem;
}

.features-header p {
  font-size: 1.125rem;
  color: #4a4a4a;
  margin-bottom: 2rem;
}

/* Thumbnail video wrapper */
.video-wrapper {
  position: relative;
  max-width: 850px;
  margin: 3rem auto;
  cursor: pointer;
}

.video-thumbnail {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  background: rgba(0,0,0,0.7);
  color: #ffffff;
  border-radius: 50%;
  font-size: 2rem;
  line-height: 70px;
  text-align: center;
  pointer-events: none;
  transition: transform 0.3s ease, background 0.3s ease;
}

.video-wrapper:hover .play-button {
  transform: translate(-50%, -50%) scale(1.1);
  background: rgba(0,0,0,0.9);
}

/* Video Modal */
.video-modal {
  display: none; /* hidden by default */
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
}

.video-modal-content {
  position: relative;
  max-width: 1000px;
  width: 90%;
}

.close-modal {
  position: absolute;
  top: -20px;
  right: -20px;
  color: white;
  font-size: 40px;
  cursor: pointer;
}
