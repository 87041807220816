/* Averta-regular */
@font-face {
  font-family: 'Averta';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/averta/averta-regular-webfont.eot'); /* IE9 Compat Modes */
  src: local('Averta Regular'), local('Averta-Regular'),
  url('../../assets/fonts/averta/averta-regular-webfont.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/averta/averta-regular-webfont.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/averta/averta-regular-webfont.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/averta/averta-regular-webfont.ttf')
  format('truetype')
}

/* Averta-semibold */
@font-face {
  font-family: 'Averta';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/averta/averta-semibold-webfont.eot'); /* IE9 Compat Modes */
  src: local('Averta Semi-Bold'), local('Averta-Semi-Bold'),
  url('../../assets/fonts/averta/averta-semibold-webfont.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/averta/averta-semibold-webfont.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/averta/averta-semibold-webfont.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/averta/averta-semibold-webfont.ttf')
  format('truetype')
}


/* Averta-bold */
@font-face {
  font-family: 'Averta';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/averta/averta-bold-webfont.eot'); /* IE9 Compat Modes */
  src: local('Averta Bold'), local('Averta-Bold'),
  url('../../assets/fonts/averta/averta-bold-webfont.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/averta/averta-bold-webfont.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/averta/averta-bold-webfont.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/averta/averta-bold-webfont.ttf')
  format('truetype')
}

/* Roboto-Mono Regular */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/roboto-mono/roboto-mono-v13-latin-ext_latin_greek-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Mono Regular'), local('Roboto-Mono-Regular'),
  url('../../assets/fonts/roboto-mono/roboto-mono-v13-latin-ext_latin_greek-regular.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/roboto-mono/roboto-mono-v13-latin-ext_latin_greek-regular.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/roboto-mono/roboto-mono-v13-latin-ext_latin_greek-regular.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/roboto-mono/roboto-mono-v13-latin-ext_latin_greek-regular.ttf')
  format('truetype')
}

/* Roboto-Mono Regular */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/roboto-mono/roboto-mono-v13-latin-ext_latin_greek-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Mono Bold'), local('Roboto-Mono-Bold'),
  url('../../assets/fonts/roboto-mono/roboto-mono-v13-latin-ext_latin_greek-700.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/roboto-mono/roboto-mono-v13-latin-ext_latin_greek-700.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/roboto-mono/roboto-mono-v13-latin-ext_latin_greek-700.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/roboto-mono/roboto-mono-v13-latin-ext_latin_greek-700.ttf')
  format('truetype')
}
